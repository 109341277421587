<template>
  <div class="select-tab">
    <div  v-for="(item, i) in listTab" :key="i" :class="[getClassItem(item)]">
      <div :class="getClassItem(item) + '__postion'">
        <div :class="getClassItem(item) + '__postion--line'" :style="i === 0 && 'opacity: 0'"></div>
        <div :class="getClassItem(item) + '__postion--index'">{{ item.index + 1 }}</div>
        <div :class="getClassItem(item) + '__postion--line'" :style="i === 3 && 'opacity: 0'"></div>
      </div>
      <div :class="getClassItem(item) + '__label'">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectTab",
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  computed: {
    listTab() {
      return [
        {
          index: 0,
          label: this.$t("register_reduction.label_enter_information"),
        },
        {
          index: 1,
          label: this.$t("register_reduction.label_enter_emission"),
        },
        {
          index: 2,
          label: this.$t("icp_target_integration.label_icp_step")
        },
        {
          index: 3,
          label: this.$t("register_reduction.label_completion"),
        }
      ];
    } 
  },
  methods: {
    getClassItem(item) {
      return item.index === this.step ? 'select-tab-item-active' : 'select-tab-item'
    }
  }
}
</script>

<style lang="scss" scoped>
.select-tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 560px;
  margin: 0 auto;
  &-item, &-item-active {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: calc(100% / 3);
    max-height: 61px;
     &__label {
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.33px;
        text-align: center;
        color: $monoBlack;
      }
    
    &__postion {
      display: flex;
      align-items: center;
      &--line {
        background: rgba(121, 134, 134, 0.12);
        height: 2px;
        width: calc((100% - 20px) / 2);
      }
      &--index {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(121, 134, 134, 0.12);
        color: $monoDark;
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
      }
    }
    &-active {
      &__postion {
        &--line {
          background: $goldMid;
        }
        &--index {
          background: $goldMid;
          color: $monoWhite;
        }
      }
      &:first-child  {
      .select-tab-item__postion {
        .select-tab-item__postion--line {
          &:first-child {
            opacity: 0;
          }
        }
      }
    }
    &:last-child {
      .select-tab-item__postion {
        .select-tab-item__postion--line {
          &:last-child {
            opacity: 0;
          }
        }
      }
    }
    }
    &:first-child  {
      .select-tab-item__postion {
        .select-tab-item__postion--line {
          &:first-child {
            opacity: 0;
          }
        }
      }
    }
    &:last-child {
      .select-tab-item__postion {
        .select-tab-item__postion--line {
          &:last-child {
            opacity: 0;
          }
        }
      }
    }
  }
}
@include desktop {
  .select-tab {
    &-item, &-item-active {
      gap: 12px;
       &__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
        text-align: center;
        color: $monoBlack;
      }
    }
  }
}
</style>