<template>
  <div class="pulldown-wrapper" v-click-outside="handleClickOutSite" @click="handleFocusWiththin" :class="{'disabled' : disabled || itemCount === 0, 'has-arrow': arrowDownBtn}">
    <div id="dropdownInputText" :class="{'focus-pull-down': focusWithin, 'selection-show': selectionShow, 'has-arrow': arrowDownBtn}" @click="toggleDropdown()" @mouseover="onIsHover" @mouseout="offIsHover">
      <div v-if="!dropdownInputText[0]" class="placeholder" :class="{'center-text': !arrowDownBtn}">{{ placeholder }}</div>
      <div v-if="dropdownInputText[0]" class="input-text">{{ dropdownInputText[0] }}</div>
      <div v-if="dropdownInputText[1] > 0 && itemCount > 0 && getSelectedData.length + 1 != methodsList.length " class="count-hide-text">{{ dropdownInputText[1] }}</div>
      <img v-if="arrowDownBtn" :src="isHover ? getArrowTopImage() : getArrowImage()" alt="" class="arrow-down" :class="{'arrow-top': selectionShow}" />
    </div>
    <div v-if="arrowDownBtn">
      <div
        v-if="selectionShow"
        class="selection-pulldown"
        id="style-scroll"
        :class="{'item-scroll-none': methodsList.length <= 8, 'method-list': width !== '50%', 'full-width': isFullWidth}"
      >
        <div
          v-for="(item, index) in methodsList"
          :key="index"
          class="item pull-down-text-color"
        >
          <div class="export-checkbox">
            <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="item.text" @change="handleCheckAllData()" />
            <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="item.text" @change="handleCheckData()" />
            <label :for="item.text">{{ item.text }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calcText } from '@/utils/calcText';
import CommonButton from '@/components/utils/button.vue';
import { mapState } from "vuex";
import debounce from 'lodash/debounce';

export default {
  components: { CommonButton },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'スコープ/カテゴリ/算定方法を選択',
    },
    width: {
      type: String,
      default: '100%'
    },
    arrowDownBtn: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemCount: {
      type: Number,
      default: 0
    },
    showFullText: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    hasSearchPullDownNormal: {
      type: Boolean,
      default: false,
    },
    isSelectProduct: {
      type: Boolean,
      default: false,
    },
    searchPlacehoder: {
      type: String,
      default: null
    },
    isReduction: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Boolean,
      default: false,
    },
    getAllText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    selectionShow: false,
    boxWidth     : 0,
    resizeTimer  : null,
    leftPosition : 0,
    isCollapsed: [],
    isCollapsedLayer1: [],
    maxInputLength: 435,
    checkboxKey: 0,
    isChangeGetAll: false,
    getAllData: false,
    searchText: null,
    dataSearch: [],
    focusWithin:false,
    isHover: false,
    getSelectedData: [],
    methodsList: []
  }),
  mounted() {
    this.maxInputLength = document.getElementById('dropdownInputText').clientWidth;
    this.dataSearch = this.dataList;
    this.methodsList = this.dataList;
    this.getSelectedData = this.selectedData;
    if(this.getSelectedData.length === this.itemCount || ([...new Set(this.getSelectedData)].length === this.itemCount && this.selectedCompany)) {
      this.getAllData = true
    }
  },
  computed: {
    ...mapState("userData", ["currentUser","planType"]),
    getSearchPlaceholder() {
      return this.searchPlacehoder || this.$t("b_export_report.placeholder_search_for_corporations");
    },
    labelBtnConfirm() {
      return this.$t("a_export_report.button_decision");
    },
    placeholderSearch() {
      return this.$t('register_user_group.placeholder_find_user_id');
    },
    labelBtnCancel() {
      return this.$t("a_export_report.button_cancel");
    },
    dropdownInputText() {
      if (this.isSelectProduct) {
        return calcText(this.getSelectedData, this.maxInputLength);
      }
      if (!this.showFullText) {
        if (this.isReduction && this.getSelectedData.length === this.itemCount) {
          return calcText(this.getAllText, this.maxInputLength);
        }
        let lessTextArr = this.getSelectedData?.map(item => {
          let stringToArrText = item.split('_');
          if (this.disabled) {
            stringToArrText = item.split('.');
          }
          return stringToArrText[stringToArrText.length - 1].trim();
        })
        if(this.isReduction) {
          const trimmedArray = lessTextArr.map(item => item.trim());
          const uniqueSet = new Set(trimmedArray);
          if(this.selectedCompany && ([...new Set(this.getSelectedData)].length === this.itemCount)) {
            return calcText(this.getAllText, this.maxInputLength);
          }
          return calcText(Array.from(uniqueSet), this.maxInputLength);
        }
        return calcText(lessTextArr, this.maxInputLength);
      }
      return calcText(this.getSelectedData, this.maxInputLength);
    },
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 500);
  },
  watch: {
    methodsList: {
      handler() {
        if (this.isReduction) {
          if (this.getSelectedData.length < this.itemCount) {
            this.getAllData = false;
            this.isChangeGetAll = true;
          }
        }
      }
    },
  },
  methods: {
    performSearch() {
      const searchTerm = this.searchText.toLowerCase().trim();
      this.methodsList = this.dataSearch.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()))
    },
    toggleDropdown() {
      this.maxInputLength = document.getElementById('dropdownInputText').clientWidth
      if (this.disabled || this.itemCount === 0) return;
      this.selectionShow = !this.selectionShow;
      if (!this.selectionShow) {
        this.$emit('updateData', this.type, this.getSelectedData);
      }
    },
    toggleDropdownSearch() {
      this.selectionShow = true;
    },
    handleClickOutSite() {
      if (this.selectionShow) {
        this.$emit('updateData', this.type, this.getSelectedData);
      }
      if (this.arrowDownBtn) {
        this.selectionShow = false;
        this.focusWithin = false;
      }
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    getArrowTopImage() {
      return require('@/assets/icons/pulldown/arrow-top.svg');
    },
    onHandleSelectData() {
      this.dropdownInputText();
      this.focusWithin = false;
      this.selectionShow = false;
      this.$emit('updateData', this.type, this.getSelectedData);
    },
    onCancelSelectData() {
      this.selectionShow= false;
      this.checkboxKey++;
      this.focusWithin = false;
    },
    isGetAll(text) {
      return text === (this.isReduction ? this.getAllText : this.$t("a_export_report.label_select_all"));
    },
    handleFocusWiththin() {
      this.focusWithin = true;
    },
    onIsHover() {
      this.isHover = true;
      if( this.disabled || this.itemCount === 0) {
        this.offIsHover();
      }
    },
    offIsHover() {
      this.isHover = false;
    },
    getMethodLayer1(index) {
      return this.dataList[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.dataList[index]?.methods[layer1Index]?.methods;
    },
    isCheckedBox(text) {
      return this.getSelectedData.indexOf(text) !== -1;
    },
    isActiveMethod(text) {
      return this.getSelectedData.filter(item => item.includes(text)).length > 0
    },
    handleCheckAllData() {
      if (this.getAllData) {
        this.getSelectedData = [];
        this.methodsList.map(item => {
          if (!item?.methods) {
            this.getSelectedData.push(`${item.text}`);
          } else {
            item?.methods?.map(itemLayer1 => {
              if (!itemLayer1?.methods) {
                this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
              } else {
                itemLayer1?.methods?.map(itemLayer2 => {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                })
              }
            })
          }
        })
        const existIndex = this.getSelectedData.indexOf(this.isReduction ? this.getAllText : GET_ALL);
        if (existIndex !== -1) {
          this.getSelectedData.splice(existIndex, 1);
        }
      } else {
        this.getSelectedData = [];
      }
    },
    handleCheckData() {
      if(!this.searchText) {
        const listData = [...this.methodsList];
          //remove item check all
        listData.shift();
        if (this.getSelectedData.length === listData.length) {
          this.getAllData = true;
        }
        else {
          this.getAllData = false;
          this.isChangeGetAll = true;
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
@import '@/components/dialogs/DialogPopupStyle/index.scss';
.input-search {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    left: 0px;
    top: 32px;
    flex: none;
    flex-grow: 0;
    outline: none;
}
.search-text {
  position: relative;
  background: #ffffff;
  .search-icon {
    position: absolute;
    right: 9px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
}
.pulldown-wrapper {
  border: none;
  .selection-pulldown  {
    .item {
      .export-checkbox {
        label {
          display: inline-block;
          min-width: 180px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.pulldown-wrapper {
  #dropdownInputText:hover {
    background-color: #ffffff;
  }
}

.focus-pull-down {
    border: 2px solid $blueMid;
    border-radius: 4px;
}

.pulldown-wrapper {
  width: 100%;
  &.box-shadow-pull-down{
    #dropdownInputText {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  &.disabled {
    cursor: default;
    #dropdownInputText {
      background: $monoLight;
      color: $monoMid;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      .input-text {
        color: $monoMid;
      }
    }
    #dropdownInputText:hover {
      background-color: $monoLight!important;
      .input-text {
        color: $monoMid!important;
      }
    }
    .focus-pull-down {
      border: none!important;
      border-radius: 4px;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  .item-scroll-none {
    overflow: hidden !important;
  }
  .description-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 32px;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    position: absolute;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  .selection-pulldown {
    position: absolute;
    max-height: 370px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    margin-top: 2px;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 429.5px;
    // font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    border-radius: 4px;
    &.full-width {
      width: 100% !important;
    }
    &.method-list {
      width: calc((100% - 80px));
    }

    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        cursor: pointer;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
        }
        label {
          width: 100%;
          cursor: pointer;
        }

        &:hover {
          background: $bgLight;
        }
        input[type=checkbox]:checked {
          appearance: none;
          box-shadow: none;
          text-align: center;
          width: 16px !important;
          height: 16px !important;
          background: $blueMid;
        }
        input[type=checkbox]:checked:after {
          content: url('../../../../assets/icons/checkedbox.svg');
          color: $monoWhite;
          position: absolute;
          top: 6px;
          left: 17px;
        }
        input[type=checkbox]:checked:before {
          content: "";
          height: 41px;
          border: 2px solid $blueMid;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .toggle-wrapper {
        width: 100%;

        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          .toggle-icon {
            width: 10px;
            height: 10px;
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
            input[type=checkbox]:checked:after {
              content: url('../../../../assets/icons/checkedbox.svg');
              color: $monoWhite;
              position: absolute;
              top: 6px;
              left: 41px;
            }
          }
        }
      }
    }
    .search-text {
      position: relative;
      .input-elic {
        width: 100% !important;
        margin: unset;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  #dropdownInputText {
    height: 40px;
    background: $monoOffWhite;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }
    .count-hide-text {
      color: $monoWhite;
      background: $bgDark;
      border-radius: 10px;
      font-size: 12px;
      line-height: 8px;
      width: 19px;
      height: 19px;
      padding: 5px 6px 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;
      letter-spacing: 0.03em;
      font-weight: 500;
      margin-left: 8px;
    }
    .placeholder {
      color: $monoMid;
      // font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 0.03em;
      &.center-text {
        text-align: center;
      }
    }
    .arrow-down {
      position: absolute;
      right: 14px;
      // padding-left: 14px;
    }
  }
  .arrow-top {
    rotate: (180deg);
  }
  &:not(.has-arrow) {
    &:hover {
    #dropdownInputText {
      background: $goldLight;
      cursor: pointer;
      .input-text {
        color: $monoWhite;
      }
      .placeholder {
        &.center-text {
          color: $monoWhite;
        }
      }
      &.selection-show {
        background-color: $monoOffWhite;
        .input-text {
          color: $monoBlack;
        }
      }
    }
  }
  }
}
.reduction-settings {
  #dropdownInputText {
    padding: 7px 16px 9px;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
    .placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@include desktop {
  .pulldown-wrapper {
    .selection-pulldown {
      width: 238px;

      &.method-list {
        width: 496px;
      }
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
