<template>
  <div class="pulldown-wrapper" @click="handleFocusWiththin" :class="{'disabled' : disabled || itemCount === 0, 'has-arrow': arrowDownBtn}">
    <div id="dropdownInputText" :class="{'focus-pull-down': focusWithin, 'selection-show': selectionShow, 'has-arrow': arrowDownBtn}" @click="toggleDropdown()" @mouseover="onIsHover" @mouseout="offIsHover">
      <div v-if="!dropdownInputText()[0]" class="placeholder center-text">{{ placeholder }}</div>
      <div v-if="dropdownInputText()[0]" class="input-text">{{ dropdownInputText()[0] }}</div>
      <div v-if="dropdownInputText()[1] > 0 && itemCount > 0 && getSelectedData.length + 1 != methodsList.length " class="count-hide-text">{{ dropdownInputText()[1] }}</div>
      <img v-if="arrowDownBtn" :src="isHover ? getArrowTopImage() : getArrowImage()" alt="" class="arrow-down" :class="{'arrow-top': selectionShow}" />
    </div>
    <v-dialog
      v-model="selectionShow"
      ref="dialog"
      :width="boxWidth"
      content-class="dialog-popup radio-dialog checkbox-popup"
      @click:outside="handleClickOutSite"
    >
      <div class="head-dialog-popup">
        <span class="head-image-dialog-popup__text over-text-sp">{{placeholder}}</span>
        <div class="selected-count over-text-sp">{{ getSelectedData.length }}{{ $t("a_export_report.label_items_are_being_selected") }}</div>
      </div>
      <hr class="divider" />
      <div class="content-dialog-popup">
        <div
          v-if="!isSelectMethods"
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse']"
        > 
          <div v-if="hasSearch" class="search-text">
            <input v-model="searchText" type="text" class="input-elic" :placeholder="searchPlacehoder" style="width: 100%;" @keyup="performSearch">
            <img :src="require('@/assets/icons/pulldown/search-icon.svg')" alt="search icon" class="search-icon">
          </div>
          <v-virtual-scroll bench="3" :items="methodsList" :item-height="41" :height="methodsList.length < 11 ? 41 * methodsList.length : 41 * 11"
          min-width="94">
            <template v-slot:default="{ item }">
              <div class="item pull-down-text-color">
                <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
                  <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${type}`" @change="handleCheckAllData($event)" />
                  <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${type}`" @change="handleCheckData()" />
                  <label :for="`${item.text}_${type}`">{{ item.text }}</label>
                </div>
              </div>
            </template>
          </v-virtual-scroll>
        </div>
        <div v-else
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse', isSelectMethods ? 'select-methods' : '']"
        >
          <div
            v-for="(item, index) in methodsList"
            :key="index"
            class="item pull-down-text-color"
          >
            <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
              <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item[itemValue]" :id="`${item.text}_${hasSearch}`" @change="handleCheckAllData($event)" />
              <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item[itemValue]" :id="`${item.text}_${hasSearch}`" @change="handleCheckData()" />
              <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
            </div>
            <div v-else class="toggle-wrapper layer1">
              <div v-if="isCollapsedProp" class="toggle-label export-checkbox-multi-method"  :class="isActiveMethod(item.text) && 'is-checked'">
                <div v-if="item.methods.length" class="export-checkbox" :class="{'is-checked': item.selectAll }" style="padding: 0">
                  <input v-model="item.selectAll" @change="checkAllSelectedLayer1($event, item)" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
                </div>
                <img :src="getArrowImage()" alt="" @click="toggleCollapse(index)" class="toggle-icon" :class="{'arrow-top': isCollapsed[index]}" />
              </div>
              <div class="content-container" v-if="isCollapsed[index] || !isCollapsedProp">
                <div
                  class="item pull-down-text-color"
                  v-for="(itemLayer1, layer1Index) in getMethodLayer1(index)"
                  :key="layer1Index"
                >
                <div v-if="itemLayer1.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text)}">
                  <input v-model="getSelectedData" @change="selectedCheckboxLayer1($event, item)" type="checkbox" :label="itemLayer1.row_num_pattern" :value="item.text + '_' + itemLayer1.text" :id="`${item.text}_${itemLayer1.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer1.text }}</label>
                </div>
                <div v-else class="toggle-wrapper layer2">
                  <div class="toggle-label" :class="isActiveMethod(item.text + '_' + itemLayer1.text) && 'is-checked'">
                    <div v-if="itemLayer1.methods.length" class="export-checkbox" :class="{'is-checked': itemLayer1.selectAll }" style="padding: 0">
                      <input v-model="itemLayer1.selectAll" @change="checkAllSelectedLayer2($event, itemLayer1, item)" type="checkbox" :label="itemLayer1.row_num_pattern" :value="`${itemLayer1.text}_${layer1Index}`" :id="`${item.text}_${itemLayer1.text}_${hasSearch}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}`">{{ itemLayer1.text }}</label>
                    </div>
                    <img v-if="isCollapsedProp" @click="toggleCollapseLayer1(index + layer1Index)" :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsedLayer1[index + layer1Index]}" />
                  </div>
                  <div class="content-container export-checkbox-multi-method" v-if="isCollapsedLayer1[index + layer1Index] || !isCollapsedProp">
                    <div
                      class="item pull-down-text-color"
                      v-for="(itemLayer2, layer2Index) in getMethodLayer2(layer1Index, index)"
                      :key="layer2Index"
                    >
                    <div v-if="itemLayer2.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text + '_' + itemLayer2.text)}">
                      <input v-model="getSelectedData" @change="selectedCheckboxLayer2($event, itemLayer1, item)" type="checkbox" :label="itemLayer2.row_num_pattern" :value="item.text + '_' + itemLayer1.text + '_' + itemLayer2.text" :id="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer2.text }}</label>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action-dialog-popup button-bottom-dialog-popup">
        <common-button class="cancle-btn" @action="onCancelSelectData()" :label="labelBtnCancel"></common-button>
        <common-button class="confirm" @action="onHandleSelectData()" type="colored" :label="labelBtnConfirm"></common-button>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import {GET_ALL} from '@/constants/export-report.js'
import { calcText } from '@/utils/calcText';
import CommonButton from '@/components/utils/button.vue';
import { mapState } from "vuex";
import debounce from 'lodash/debounce';

export default {
  components: { CommonButton },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'スコープ/カテゴリ/算定方法を選択',
    },
    width: {
      type: String,
      default: '100%'
    },
    arrowDownBtn: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemCount: {
      type: Number,
      default: 0
    },
    showFullText: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    hasSearchPullDownNormal: {
      type: Boolean,
      default: false,
    },
    isSelectProduct: {
      type: Boolean,
      default: false,
    },
    searchPlacehoder: {
      type: String,
      default: null
    },
    isReduction: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Boolean,
      default: false,
    },
    getAllText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: 'text',
    },
  },
  data: () => ({
    selectionShow: false,
    boxWidth     : 0,
    resizeTimer  : null,
    leftPosition : 0,
    isCollapsed: [],
    isCollapsedLayer1: [],
    maxInputLength: 435,
    methodsList: [],
    checkboxKey: 0,
    isChangeGetAll: false,
    getAllData: false,
    searchText: null,
    dataSearch: [],
    focusWithin:false,
    isHover: false,
    getSelectedData: [],
  }),
  mounted() {
    this.maxInputLength = document.getElementById('dropdownInputText').clientWidth;
    this.methodsList = [...new Set(this.dataList)];
    this.dataSearch = [...new Set(this.dataList)];
    this.getSelectedData = [...new Set(this.selectedData)];
    if(this.getSelectedData.length === this.itemCount || ([...new Set(this.getSelectedData)].length === this.itemCount && this.selectedCompany)) {
      this.getAllData = true
    }
    if(this.type === 'listScopeCategories') {
      this.handleSelectedData()
    }
  },
  computed: {
    ...mapState("userData", ["currentUser","planType"]),
    getSearchPlaceholder() {
      return this.searchPlacehoder || this.$t("b_export_report.placeholder_search_for_corporations");
    },
    labelBtnConfirm() {
      return this.$t("a_export_report.button_decision");
    },
    placeholderSearch() {
      return this.$t('register_user_group.placeholder_find_user_id');
    },
    labelBtnCancel() {
      return this.$t("a_export_report.button_cancel");
    },
    isSelectMethods() {
      return this.type === 'listScopeCategories';
    }
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 500);
  },
  watch: {
    methodsList: {
      handler() {
        if (this.isReduction) {
          if (this.getSelectedData.length < this.itemCount) {
            this.getAllData = false;
            this.isChangeGetAll = true;
          }
        }
      }
    },
  },
  methods: {
    performSearch() {
      const searchTerm = this.searchText.toLowerCase().trim();
      this.methodsList = this.dataSearch.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()))
    },
    toggleDropdown() {
      this.maxInputLength = document.getElementById('dropdownInputText').clientWidth
      if (this.disabled || this.itemCount === 0) return;
      this.selectionShow = !this.selectionShow;
      if (this.selectionShow) {
        // handle for cancel button when uncheck selectAll checkbox -> open again
        if (this.getSelectedData.length === this.itemCount) {
          this.getAllData = true;
        } else {
          this.getAllData = false;
        }
      }
    },
    toggleDropdownSearch() {
      this.selectionShow = true;
    },
    handleClickOutSite() {
      this.onCancelSelectData();
    },
    onWindowResize() {
      window.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    getArrowTopImage() {
      return require('@/assets/icons/pulldown/arrow-top.svg');
    },
    onHandleSelectData() {
      this.focusWithin = false;
      this.selectionShow = false;
      this.$emit('updateData', this.type, this.getSelectedData);
    },
    onCancelSelectData() {
      this.selectionShow = false;
      this.focusWithin = false;
      this.getSelectedData = [];
      this.methodsList.forEach(item => {
        let itemsChecked = 0,
            methodsLength = 0;
        if (!item?.methods) {
          methodsLength += 1;
          const existIndex = this.selectedData.indexOf(`${item.text}`);
          if (existIndex >= 0) {
            this.getSelectedData.push(`${item.text}`);
            itemsChecked += 1;
          }
        } else {
          item?.methods?.forEach(itemLayer1 => {
            if (!itemLayer1?.methods) {
              methodsLength += 1;
              const existIndex = this.selectedData.indexOf(`${item.text}_${itemLayer1.text}`);
              if (existIndex >= 0) {
                this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
                itemsChecked += 1;
              }
            } else {
              let itemsLayer2Checked = 0,
                  methodsLayer2Length = 0;
              itemLayer1?.methods.forEach(itemLayer2 => {
                methodsLength += 1;
                methodsLayer2Length += 1;
                const existIndex = this.selectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                if (existIndex >= 0) {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                  itemsChecked += 1;
                  itemsLayer2Checked += 1;
                }
              })
              itemLayer1.selectAll = itemsLayer2Checked === methodsLayer2Length ? true : false;
            }
          })
        }
        item.selectAll = itemsChecked === methodsLength ? true : false;
      });
    },
    dropdownInputText() {
      if (this.selectionShow) {
        // when selecting, dont show data
        return [];
      }
      if (this.isSelectProduct) {
        return calcText(this.getSelectedData, this.maxInputLength);
      }
      if (!this.showFullText) {
        if (this.isReduction && this.getSelectedData.length === this.itemCount) {
          return calcText(this.getAllText, this.maxInputLength);
        }
        let lessTextArr = this.getSelectedData.map(item => {
          let stringToArrText = item.split('_');
          if (this.disabled) {
            stringToArrText = item.split('.');
          }
          return stringToArrText[stringToArrText.length - 1].trim();
        })
        if(this.isReduction) {
          const trimmedArray = lessTextArr.map(item => item.trim());
          const uniqueSet = new Set(trimmedArray);
          if(this.selectedCompany && ([...new Set(this.getSelectedData)].length === this.itemCount)) {
            return calcText(this.getAllText, this.maxInputLength);
          }
          return calcText(Array.from(uniqueSet), this.maxInputLength);
        }
        return calcText(lessTextArr, this.maxInputLength);
      }
      return calcText(this.getSelectedData, this.maxInputLength);
    },
    isGetAll(text) {
      return text === (this.isReduction ? this.getAllText : this.$t("a_export_report.label_select_all"));
    },
    handleFocusWiththin() {
      this.focusWithin = true;
    },
    onIsHover() {
      this.isHover = true;
    },
    offIsHover() {
      this.isHover = false;
    },
    getMethodLayer1(index) {
      return this.dataList[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.dataList[index]?.methods[layer1Index]?.methods;
    },
    isCheckedBox(text) {
      return this.getSelectedData.indexOf(text) !== -1;
    },
    isActiveMethod(text) {
      return this.getSelectedData.filter(item => item.includes(text)).length > 0
    },
    handleCheckAllData(event) {
      if (event.target.checked) {
        this.getSelectedData = [];
        this.methodsList.forEach(item => {
          if (!item?.methods) {
            this.getSelectedData.push(`${item.text}`);
          } else {
            item.selectAll = true;
            item?.methods?.forEach(itemLayer1 => {
              if (!itemLayer1?.methods) {
                this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
              } else {
                itemLayer1.selectAll = true;
                itemLayer1?.methods?.forEach(itemLayer2 => {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                })
              }
            })
          }
        })
        const existIndex = this.getSelectedData.indexOf(this.isReduction ? this.getAllText : GET_ALL);
        if (existIndex !== -1) {
          this.getSelectedData.splice(existIndex, 1);
        }
      } else {
        this.getSelectedData = [];
        this.methodsList.forEach(item => {
          if (item?.methods) {
            item.selectAll = false;
            item?.methods.forEach(itemLayer1 => {
              if (itemLayer1?.methods) {
                itemLayer1.selectAll = false;
              }
            })
          }
        })
      }
    },
    handleCheckData() {
      if (this.getSelectedData.length < this.itemCount) { // remove getAll when uncheck item
        this.isChangeGetAll = true;
        this.getAllData = false;
      } else {
        this.isChangeGetAll = false;
        this.getAllData = true;
      }
    },
    handleSearchText() {
      this.debouncedSearch();
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    toggleCollapseLayer1(index) {
      this.$set(this.isCollapsedLayer1, index, !this.isCollapsedLayer1[index]);
    },
    checkAllSelectedLayer1(event, item) {
      if (event.target.checked) {
        item?.methods.forEach(itemLayer1 => {
          if (!itemLayer1?.methods) {
            // if dont have layer2
            const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}`);
            if (existIndex < 0) {
              this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
            }
          } else {
            itemLayer1.selectAll = true;
            itemLayer1?.methods.forEach(itemLayer2 => {
              const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              if (existIndex < 0) {
                this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              }
            })
          }
        })
      } else {
        item?.methods.map(itemLayer1 => {
          if (!itemLayer1?.methods) {
            // if dont have layer2
            const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}`);
            if (existIndex >= 0) {
              this.getSelectedData.splice(existIndex, 1);
            }
          } else {
            itemLayer1.selectAll = false;
            itemLayer1?.methods.map(itemLayer2 => {
              const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              if (existIndex >= 0) {
                this.getSelectedData.splice(existIndex, 1);
              }
            })
          }
        })
      }
      this.handleCheckData();
    },
    checkAllSelectedLayer2(event, itemsLayer1, item) {
      if (event.target.checked) {
        let itemsChecked = 0,
        methodsLength = 0;
        item?.methods.forEach(itemLayer1 => {
          if (!itemLayer1?.methods) {
            // if dont have layer2
            methodsLength += 1;
            const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}`);
            if (existIndex >= 0) {
              itemsChecked += 1;
            }
          } else {
            itemLayer1?.methods.forEach(itemLayer2 => {
              methodsLength += 1;
              const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              if (itemsLayer1.text === itemLayer1.text) {
                if (existIndex < 0) {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                }
                itemsChecked += 1;
              } else {
                if (existIndex >= 0) {
                  itemsChecked += 1;
                }
              }
            })
          }
        })
        if (itemsChecked === methodsLength) {
          item.selectAll = true;
        }
      } else {
        itemsLayer1?.methods.forEach(itemLayer2 => {
          const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemsLayer1.text}_${itemLayer2.text}`);
          if (existIndex >= 0) {
            this.getSelectedData.splice(existIndex, 1);
          }
        })
        item.selectAll = false;
      }
      this.handleCheckData();
    },
    selectedCheckboxLayer1(event, item) {
      if (event.target.checked) {
        let itemsChecked = 0,
        methodsLength = 0;
        item?.methods.forEach(itemLayer1 => {
          if (!itemLayer1?.methods) {
            // if dont have layer2
            methodsLength += 1;
            const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}`);
            if (existIndex >= 0) {
              itemsChecked += 1;
            }
          } else {
            itemLayer1?.methods.forEach(itemLayer2 => {
              methodsLength += 1;
              const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              if (existIndex >= 0) {
                itemsChecked += 1;
              }
            })
          }
        })
        if (itemsChecked === methodsLength) {
          item.selectAll = true;
        }
      } else {
        item.selectAll = false;
      }
      this.handleCheckData();
    },
    selectedCheckboxLayer2(event, itemsLayer1, item) {
      if (event.target.checked) {
        let itemsChecked = 0,
        selectedItemsChecked = 0,
        methodsLength = 0;
        item?.methods.forEach(itemLayer1 => {
          if (!itemLayer1?.methods) {
            // if dont have layer2
            methodsLength += 1;
            const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}`);
            if (existIndex >= 0) {
              itemsChecked += 1;
            }
          } else {
            itemLayer1?.methods.forEach(itemLayer2 => {
              methodsLength += 1;
              const existIndex = this.getSelectedData.indexOf(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
              if (existIndex >= 0) {
                itemsChecked += 1;
                if (itemsLayer1.text === itemLayer1.text) {
                  selectedItemsChecked += 1;
                }
              }
            })
          }
        })
        if (selectedItemsChecked === itemsLayer1.methods.length) {
          itemsLayer1.selectAll = true;
        }
        if (itemsChecked === methodsLength) {
          item.selectAll = true;
        }
      } else {
        itemsLayer1.selectAll = false;
        item.selectAll = false;
      }
      this.handleCheckData();
    },
    handleSelectedData() {
      this.methodsList.forEach(scope => {
        let methodSelect = 0
        let methodLength = 0
        scope.methods?.forEach(method => {
          methodLength ++
          if(this.selectedData.includes(`${scope.text}_${method.text}`)) {
            methodSelect ++
          }

          let method2Select = 0
          let method2Length = 0
          if(method?.methods?.length) {
            methodLength --
          }
          method?.methods?.forEach(method2 => {
            method2Length ++
            methodLength ++
            if(this.selectedData.includes(`${scope.text}_${method.text}_${method2.text}`)) {
              method2Select ++
              methodSelect ++
            }
          })
          if(method2Length === method2Select) {
            method.selectAll = true
          }
        })

        if(methodSelect === methodLength) {
          scope.selectAll = true
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
@import '@/components/dialogs/DialogPopupStyle/index.scss';
.input-search {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    left: 0px;
    top: 32px;
    flex: none;
    flex-grow: 0;
    outline: none;
}
.search-text {
  position: relative;
  background: #ffffff;
  .search-icon {
    position: absolute;
    right: 9px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
}
.pulldown-wrapper {
  border: none;
}

.pulldown-wrapper {
  #dropdownInputText:hover {
    background-color: #ffffff;
  }
}

.focus-pull-down {
    border: 2px solid $blueMid;
    border-radius: 4px;
}

.pulldown-wrapper {
  width: 100%;
  &.box-shadow-pull-down{
    #dropdownInputText {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
  &.disabled {
    #dropdownInputText {
      background: $monoLight;
      color: $monoMid;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      cursor: default !important;
      .input-text {
        color: $monoMid;
      }
    }
    #dropdownInputText:hover {
      background-color: $monoLight!important;
      .input-text {
        color: $monoMid!important;
      }
      .placeholder {
        &.center-text {
          color: #99a6a9 !important;
        }
      }
    }
    .focus-pull-down {
      border: none!important;
      border-radius: 4px;
    }
  }
  .item-scroll-none {
    overflow: hidden !important;
  }
  .description-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 32px;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    position: absolute;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  #dropdownInputText {
    height: 40px;
    background: $monoOffWhite;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }
    .count-hide-text {
      color: $monoWhite;
      background: $bgDark;
      border-radius: 10px;
      font-size: 12px;
      line-height: 8px;
      // width: 19px;
      height: 19px;
      padding: 5px 6px 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;
      letter-spacing: 0.03em;
      font-weight: 500;
      margin-left: 8px;
    }
    .placeholder {
      color: $monoMid;
      // font-family: 'Source Han Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 0.03em;
      &.center-text {
        text-align: center;
      }
    }
    .arrow-down {
      position: absolute;
      right: 14px;
      // padding-left: 14px;
    }
  }
  .arrow-top {
    rotate: (180deg);
  }
  &:not(.has-arrow) {
    &:hover {
    #dropdownInputText {
      background: $goldLight;
      cursor: pointer;
      .input-text {
        color: $monoWhite;
      }
      .placeholder {
        &.center-text {
          color: $monoWhite;
        }
      }
      &.selection-show {
        background-color: $monoOffWhite;
        .input-text {
          color: $monoBlack;
        }
      }
    }
  }
  }
}
.reduction-settings {
  #dropdownInputText {
    padding: 7px 16px 9px;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    .input-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
    .placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
}
.checkbox-popup {
  .head-dialog-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  .selection-pulldown {
    max-height: calc(100vh - 320px - 113px);
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    z-index: 99;
    &.select-methods {
      overflow-y: scroll;
    }
    .item {
      min-height: 40px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-bottom: 0;
      border-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;

      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        white-space: normal;
        cursor: pointer;
        position: relative;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
          position: relative;

          &[type=checkbox]:checked {
            appearance: none;
            box-shadow: none;
            text-align: center;
            width: 16px !important;
            height: 16px !important;
            background: $blueMid;
          }
          &[type=checkbox]:checked:before {
            content: url('../../../../assets/icons/checkedbox.svg');
            color: $monoWhite;
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
        label {
          width: calc(100% - 40px);
          cursor: pointer;
          word-wrap: break-word;
        }
        
        &:hover {
          background: $bgLight;
          font-weight: 500;
        }
        &.is-checked {
          &::before {
            content: '';
            height: 100%;
            border: 2px solid $blueMid;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .toggle-wrapper {
        width: 100%;
        cursor: pointer;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          white-space: normal;
          &.export-checkbox-multi-method {
            padding: 7px 14px 9px 16px;
          }
          .export-checkbox{
            padding: 0;
            background: transparent;
            &::before{
              display: none;
            }
          }
          &.is-checked {
            &::before {
              content: "";
              height: 40px;
              border: 2px solid $blueMid;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .toggle-icon {
            width: 10px;
            height: 10px;
            &.arrow-top {
              rotate: (180deg);
            }
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
          .export-checkbox {
            padding: 7px 0px 9px 40px;
          }
        }
        .content-container {
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          &.export-checkbox-multi-method{
            .export-checkbox {
              padding: 7px 0px 9px 65px;
            }
          }
        }
      }
    }
    .search-text {
      position: relative;
      background: $monoWhite;
      .input-elic {
        width: 100% !important;
        margin: unset;
        box-shadow: unset;
        background: $monoWhite;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }

    &.dont-collapse {
      .item {
        .export-checkbox {
          padding: 7px 0px 9px 16px;
        }
        .layer1 {
          .export-checkbox {
            padding: 7px 0px 9px 16px;
          }
          .layer2 {
            .toggle-label {
              display: block;
              padding: 7px 14px 9px 16px;
              font-size: 13px;
              font-weight: 700;
              letter-spacing: 0.05em;
              color: $monoBlack;
              background: rgba(121, 134, 134, 0.12);
              &.is-checked {
                &::before {
                  content: "";
                  height: 40px;
                  border: 2px solid $blueMid;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
            .export-checkbox {
              padding: 7px 14px 9px 16px;
            }
          }
        }
      }
    }
    .v-virtual-scroll::-webkit-scrollbar {
      width: 16px;
    }
    .v-virtual-scroll::-webkit-scrollbar-thumb {
      background-color: #bfd4d9;
      border-radius: 10px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<style lang="scss">
.checkbox-popup {
  width: 560px !important;
  max-width: 560px !important;
  max-height: calc(100vh - 320px) !important;
  background: $monoOffWhite;
  .divider {
    width: 100% !important;
  }
  .over-text-sp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (min-width: 375px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        // padding-bottom: 0 !important;
        width: 100%;
        gap: 8px;
        .common-btn {
          width: calc((100% - 8px) / 2) !important;
          max-width: unset !important;
          margin-inline: 0 !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
  @media only screen and (min-width: 1024px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        .common-btn {
          width: 100%;
          max-width: 268px !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
}
</style>