<template>
  <div class="reduction-item" :id="'compare' + index">
    <div class="reduction-item_head">
      <h2 class="reduction-item_head--title">{{ getTitle || $t("register_reduction.title_form_target_emission") }}</h2>
      <div class="reduction-item_head-action" @click="handleDeleteItem" v-if="index > 0">
        <img src="@/assets/images/reductions/delete.svg" class="reduction-item_head-action--icon" />
        <img src="@/assets/images/reductions/deleteActive.svg" class="reduction-item_head-action--icon active" />
      </div>
    </div>

    <!-- TODO: apply multi language -->
    <!-- title when item is compare -->
    <div class="reduction-item-compare" v-if="index > 0">
      <div class="reduction-item-label require mb-2">
        <div class="reduction-item-label--text">{{ $t("register_reduction.label_input_title") }}</div>
        <span class="reduction-item-label--require">{{ $t("register_reduction.label_required") }}</span>
      </div>
      <InputField :inputValue="getDataTitle" :label="placeholderInputTitle" class="mb-6 input-custom color-textColor"  @update:inputValue="handleTypeTitle"/>

      <img src="@/assets/icons/icon-arrow-menu.svg" alt="" class="mx-auto" />

      <div class="reduction-item-compare-duration mt-6 mb-6">
        <div class="compare-item">
          <div class="reduction-item-label require mb-2">
            <div class="reduction-item-label--text">{{ $t("register_reduction.label_reference_year") }}</div>
            <span class="reduction-item-label--require">{{ $t("register_reduction.label_required") }}</span>
          </div>
          <v-select
            solo
            flat
            :items="getYearList"
            v-model="startAt"
            item-text="text"
            item-value="value"
            :label="placeholderInputYear"
            class="compare-item-select list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
        </div>

        <div class="compare-item">
          <div class="reduction-item-label require mb-2">
            <div class="reduction-item-label--text">{{ $t("register_reduction.label_achievement_year") }}</div>
            <span class="reduction-item-label--require">{{ $t("register_reduction.label_required") }}</span>
          </div>
          <v-select
            solo
            flat
            :items="getYearList"
            v-model="endAt"
            item-text="text"
            item-value="value"
            :label="placeholderInputYear"
            class="compare-item-select list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
        </div>
      </div>

      <img src="@/assets/icons/icon-arrow-menu.svg" alt="" class="mx-auto" />
    </div>
    
   <!-- checkbox auto input/ normal input -->
    <div class="reduction-item-condition">
      <div v-if="!isDisableInput" class="reduction-item-condition-option">
        <v-radio-group v-model="inputType" inline class="reduction-radio">
          <div class="radio-part">
            <v-radio v-for="(item, i) in inputTypes" :key="i" :label="`${item.name}`" :value="item.id" :ripple="false"></v-radio>
          </div>
        </v-radio-group>
      </div>
      <div class="reduction-item-condition-carbon" v-if="index === 0">
        <div class="reduction-checkbox" @click="toggleCheckbox">
        <div class="checkbox-box" :class="{ checked: isInputCarbon }">
          <svg v-if="isInputCarbon" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999756 3.8L4.59976 7.4L10.9998 1" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
          </svg>
        </div>
        <label class="label">{{ $t('register_reduction.checkbox_input_carbon') }}</label>
      </div>
      </div>
    </div>

    <div class="reduction-item-emission" :key="key">
      <div v-for="(item, i) in getStateYearList" :key="i" class="emission-item">
        <div class="reduction-item-label require">
          <div class="reduction-item-label--text">{{ i === 0 ? item.text + '（'+ $t("register_reduction.label_reference_year") + '）' : (i === getStateYearList.length - 1 ? item.text + '（'+ $t("register_reduction.label_achievement_year") + '）' : item.text) }}</div>
          <span class="reduction-item-label--require" v-if="index === 0">{{ $t("register_reduction.label_required") }}</span>
        </div>
        <div class="emission-item-input">
          <InputField
            :index="i"
            :year="item.value"
            :inputValue="handleGetValueInputByComputed(i, item.value)"
            :isReadOnly="isDisableInput"
            :isNumberType="true"
            class="emission-item-input--field input-custom color-textColor"
            :label="placeholderInputEmission"
            @update:inputValue="handleUpdateYearCustome"
          />
          <span class="emission-item-input--unit">t-CO2</span>
        </div>

        <div class="emission-item-input" v-if="isInputCarbon && index === 0">
          <InputField
            :index="i"
            :year="item.value"
            :inputValue="handleGetValueCarbonByComputed(i, item.value)"
            :isNumberType="true"
            class="emission-item-input--field input-custom color-textColor"
            :label="placeholderInputCarbon"
            @update:inputValue="handleUpdateCarbonCustome"
          />
          <span class="emission-item-input--unit">t-CO2</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ReductionItem',
  components: { InputField },
  data() {
    return {
      startAt: null,
      endAt: null,
      years: [],
      dataTitle: null,
      data: null,
      isValidItem: false,
      key: 0,
      inputType: 0,
      isInputCarbon: false,
      inputTypes: [
        {
          id: 0,
          name: this.$t('register_reduction.radio_select_input_manual')
        },
        {
          id: 1,
          name: this.$t('register_reduction.radio_select_input_auto')
        }
      ]
    };
  },
  props: {
    isItemCompare: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
    dataItem: {
      type: Object,
      default: () => {},
    },
    dataSetting: {
      type: Object,
      default: () => {}
    },
    isDisableInput: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('newRegisterData', ['isNextTab']),
    getTitle() {
      return this.index === 0 ? this.$t("register_reduction.title_form_target_emission") : `${this.$t("register_reduction.button_compare_object")}${this.index}`;
    },
    getDataTitle() {
      return this.dataItem.title
    },
    getYearList() {
      let yearList = [];
      let start = this.start
      let end = this.end
      if(this.$route.query?.id) {
        start = this.dataSetting?.startAt
        end = this.dataSetting?.endAt 
      }
      for (var i = end; i >= start; i--) {
        yearList.push(i);
      }
      return yearList.map((item) => {
        return {
          text: this.$t("register_reduction.fiscal_year", { year : item}),
          value: Number(item),
        };
      });
    },
    getStateYearList() {
      let dataYears = [];
      if (!this.startAt || !this.endAt) return [];
      for (let index = this.startAt; index <= this.endAt; index++) {
        if(this.dataItem.typeInput === 0) {
          dataYears.push(index);
        } else {
          if(index === this.startAt || index === this.endAt) {
            dataYears.push(index);
          }
        }
        this.inputType = this.dataItem.typeInput;
      }
      return dataYears.map((item) => {
        return {
          text: this.$t("register_reduction.fiscal_year", { year : item}),
          value: Number(item),
        };
      });
    },
    getYearObj() {
      const key = this.inputType ? 'target_emissions_auto' : 'target_emissions';
      const tagetData = this.dataItem[key]
      const dataProp = JSON.parse(tagetData)
      return dataProp
    },
    getCarbonObj() {
      const key = this.inputType ? 'carbons_auto' : 'carbons';
      const carbonData = this.dataItem[key]
      return JSON.parse(carbonData)
    },
    placeholderInputTitle() {
      return this.$t("register_reduction.placeholder_input_title");
    },
    placeholderInputYear() {
      return this.$t("register_reduction.placeholder_input_year");
    },
    placeholderInputEmission() {
      return this.$t("register_reduction.placeholder_input_target_emission");
    },
    placeholderInputCarbon() {
      return this.$t("register_reduction.placeholder_input_target_carbon");
    }
  },
  mounted() {
    this.startAt = this.start;
    this.endAt = this.end;
    // this.dataTitle = this.dataItem?.title || this.dataTitle
    if(!this.dataItem?.id){  //add compare when edit
      const startYear = this.dataItem.target_emissions && Object.keys(JSON.parse(this.dataItem.target_emissions))[0];
      const endYear = this.dataItem.target_emissions && Object.keys(JSON.parse(this.dataItem.target_emissions))[Object.keys(JSON.parse(this.dataItem.target_emissions)).length - 1];
      this.startAt =  startYear ? Number(startYear) : (this.dataItem.start || this.start);
      this.endAt = endYear ? Number(endYear) : (this.dataItem.end || this.end);
    }
    if(this.$route.query?.id  && this.dataItem?.id){
      // this.years = this.dataItem?.value
      const listYear = Object.keys(this.getYearObj)
      const startAt = Number(listYear[0]) || this.start
      const endAt = Number(listYear[listYear.length - 1]) || this.end
      this.startAt = (this.start <= this.dataItem.start_year && this.dataItem.start_year <= this.end) ? startAt : this.start;
      this.endAt = (this.start <= this.dataItem.end_year && this.dataItem.end_year <= this.end) ? endAt : this.end;
    }
    
    // this.dataSetting.target_year.type_setting ? 1 : 0
    this.isInputCarbon = this.dataSetting?.target_year?.active_offset ?? false;
    this.isValidItems()
  },
  methods: {
    ...mapActions("newRegisterData", ["updateIsNextTab"]),
    toggleCheckbox() {
      this.isInputCarbon = !this.isInputCarbon
    },
    formatYearsInfor(numbers,years) {
      const data = {};
      numbers && numbers.forEach((number, index) => {
        data[number.value] = years[index];
      });
      return data
    },
    handleGetValueInputByComputed(index, year) {
      return this.getYearObj[year]
    },
    handleGetValueCarbonByComputed(index, year) {
      return this.getCarbonObj[year]
    },
    handleGetValueInput(index) {
      const resultObject = this.years;
      if(!!resultObject) return resultObject[index] || "";
    },
    handleTypeTitle(newVal) {
      this.isValidItems()
      this.$emit('updateTitle', newVal, this.index)
    },
    handleTypeYear() {
      const dataEmit = {
        index: this.index,
        // title: this.dataTitle?.substr(0, 255),
        start_year: this.startAt,
        end_year: this.endAt,
        target_emissions: this.formatYearsInfor(this.getStateYearList, this.years),
        target_emissions_auto: this.formatYearsInfor(this.getStateYearList, this.years),
        carbons: this.formatYearsInfor(this.getStateYearList, this.years),
        carbons_auto: this.formatYearsInfor(this.getStateYearList, this.years),
        typeInput: this.inputType
      }
      if(this.$route.query?.id) {
        dataEmit.id = this.dataItem.id
      }
      this.isValidItems()
      this.$emit('getYearCompareItem', dataEmit)
    },
    handleDeleteItem() {
      this.$emit('deleteCompareItem', this.index);
    },
    handleUpdateYear(data, index) {
      this.years[index] = data
      this.handleTypeYear()
    },
    handleUpdateYearCustome(newVal, index, year) {
      if(year) {
        this.$emit('updateYearVal', this.index, year, newVal, this.inputType);
        this.isValidItems()
      }
    },
    handleUpdateCarbonCustome(newVal, index, year) {
      if(year) {
        this.$emit('updateCarbonVal', this.index, year, newVal, this.inputType);
        this.isValidItems()
      }
    }, //todo: handle logic update carbon value
    isValidItemsOld() {
      if (this.index === 0) {
        return (
          this.years.length > 0 &&
          this.years.every((item) => item !== null && item !== undefined && item !== "") &&
          this.years.length >= this.getStateYearList.length
        );
      } else {
        return (
          (this.dataTitle && this.startAt && this.endAt) ? true : false
        )
      }
    },
    getIsValidCarbon(dataCarbon) {
      const valueList = Object.values(dataCarbon);
      if(this.isInputCarbon) {
        if(this.inputType) {
          return valueList[0] && valueList[valueList.length - 1]
        } else {
          return valueList.length && valueList?.every((item) => item !== null && item !== undefined && item !== "")
        }
      } else {
        return true
      }
    },
    getIsValidEmisions(emissionData) {
      const valueList = Object.values(emissionData);
      if(this.inputType) {
        return valueList[0] && valueList[valueList.length - 1]
      } else {
        return valueList?.every((item) => item !== null && item !== undefined && item !== "")
      }
    },
    isValidItems() {
      if (this.index === 0) {
        if(!this.dataItem?.target_emissions_auto) return false
        if(!this.dataItem?.target_emissions) return false
        const keyType = this.dataItem.typeInput ? 'target_emissions_auto': 'target_emissions';
        const keyTypeCarbons = this.dataItem.typeInput ? 'carbons_auto': 'carbons';
        const data = JSON.parse(this.dataItem[keyType])
        const valueList = Object.values(data);
        let isValidLength = valueList?.length >= this.getStateYearList.length && data[this.getStateYearList[0].value] && data[this.getStateYearList[this.getStateYearList.length - 1].value];
        const dataCarbons = JSON.parse(this.dataItem[keyTypeCarbons]);

        if(this.isInputCarbon) {
          isValidLength = isValidLength && dataCarbons[this.getStateYearList[0].value] && dataCarbons[this.getStateYearList[this.getStateYearList.length - 1].value]
        }

        const isValidYear = this.getIsValidEmisions(data)
        const isValidCarbon = this.getIsValidCarbon(dataCarbons)
        this.$emit('updateIsValidItem', (isValidYear && isValidLength && isValidCarbon) ? true : false, this.index);
        return isValidYear
      } else {
        const result = (this.dataItem.title && this.startAt && this.endAt && this.startAt <= this.endAt) ? true : false
        this.$emit('updateIsValidItem', result, this.index);
        return result
      }
    },
  },
  watch: {
    dataItem: {
      handler() {
        if(this.$route.query?.id) {
          if(this.years.length) {
            this.isValidItems()
          }
        }
        if(this.dataItem.value && !this.$route.query?.id){
          this.years = this.dataItem?.value
        }
      },
      deep: true
    },
    startAt(newVal, oldVal) {
      this.isValidItem = this.isValidItems()
      this.handleTypeYear()
      if(!oldVal) return
      this.$emit('updateStartYear', newVal,this.endAt, this.index);
    },
    endAt(newVal, oldVal) {
      this.isValidItem = this.isValidItems()
      this.handleTypeYear()
      if(!oldVal) return
      this.$emit('updateEndYear', newVal, this.startAt, this.index);
    },
    inputType(newVal) {
      this.isValidItems()
      this.$emit('updateTypeInput', newVal, this.index, this.isInputCarbon);
      if(this.index) {
        this.handleTypeYear()
      }
    },
    isInputCarbon(newVal) {
      this.isValidItems()
      this.$emit('updateIsInputCarbon', newVal);
    }
  },
};
</script>
<style lang="scss"> //custome vuetify css
.reduction-item {
  &-condition {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    &-option {
      .reduction-radio {
        .v-input__control {
          .v-input__slot {
            .v-input--radio-group__input {
              .radio-part {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 8px;
                .v-radio {
                  margin: 0;
                  display: flex;
                  align-items: flex-start;
                  .v-label {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.42px;
                    color: $monoBlack;
                    padding-bottom: 2px;
                  }
                  .v-input--selection-controls__input {
                    margin-top: 5px !important;
                  }
                }
              }
            }
          }
          .v-messages {
            display: none;
          }
        }
      }
    }
    &-carbon {
      .reduction-checkbox {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 6px;
      }

      .checkbox-box {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: border-color 0.3s;
        background: $monoWhite;
      }

      .label {
        color: #404D50;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
        padding-bottom: 2px;
      }

      .checked {
        border-color: $blueMid;
        background-color: $blueMid;
      }

      .checkmark {
        width: 16px;
        height: 16px;
      }

      .checked .checkmark {
        fill: #007bff; /* Change checkmark color to blue */
      }
    }
  }
}

@include desktop {
  .reduction-item {
    &-condition {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      &-option {
        .reduction-radio {
          .v-input__control {
            .v-input__slot {
              .v-input--radio-group__input {
                .radio-part {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: row;
                  gap: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.reduction-item {
  display: flex;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: $bgLight;
  gap: 24px;
  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    &--title {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 1.6px;
      color: $goldMid;
    }
    &-action {
      display: flex;
      height: 40px;
      width: 52px;
      padding: 7px 16px 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: $monoOffWhite;
      cursor: pointer;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      &--icon {
        display: block;
        &.active {
          display: none;
        }
      }
      &:hover {
        background: $goldMid;
        .reduction-item_head-action--icon {
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }
  }
  &-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    &--text {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
    }
    &--require {
      display: flex;
      padding: 0px 4px 2px 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $goldMid;
      color: $monoWhite;
      font-size: 11px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.33px;
    }
  }
  &-compare {
    width: 100%;
    display: flex;
    flex-direction: column;
    .input-field{
      ::v-deep .v-input__slot {
        padding: 7px 0px 9px 16px !important;
      }
    }
    &-duration {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .compare-item {
        width: 100%;
        &-select {
          width: 100%;
        }
      }
    }
  }
  &-emission {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    .emission-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
        color: $monoBlack;
      }
      &-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 100%;
        .input-field{
          ::v-deep .v-input__slot {
            padding: 7px 0px 9px 16px;
          }
        }
        &--unit {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.42px;
          color: $monoDark;
        }
        &--field {
          width: calc(100% - 60px);
        }
      }
    }
  }
}

@include desktop {
  .reduction-item {
    padding: 32px;
    &-compare {
      &-duration {
        flex-direction: row;
        .compare-item {
          width: calc((100% - 24px) / 2);
        }
      }
    }
  }
}
</style>
