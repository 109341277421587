<template>
    <div class="icp-step mt-12" :class="isStickyLeftMenu && 'sticky-menu'" ref="scrollContainer">
        <div class="left-site" :class="isExpand ? 'expand' : 'no-expand'">
        <div
          class="left-site-item"
          v-for="(item, i) in menus"
          :key="i"
          :class="i === activeIndex && 'item-active'"
          @click="handleScrollToItem(i)"
        >
          <div class="left-site-item__label">{{ item.text }}</div>
          <img
            v-if="i === activeIndex"
            src="@/assets/images/reductions/iconAreaActive.svg"
            class="left-site-item__icon"
          />
          <img v-else src="@/assets/images/reductions/iconArea.svg" class="left-site-item__icon mr-2" />
        </div>
      </div>
      <div class="right-site">
            <h3>{{ $t("icp_target_integration.title_icp_step") }}</h3>
            <div class="input-icp-mode">
                <div class="text-des">{{ $t("icp_target_integration.description_icp_step") }}</div>
                <v-radio-group v-model="inputMode" inline  hide-details="auto">
                <div class="radio-part">
                    <v-radio v-for="itemType in itemsTypeInput" :key="itemType.id" :label="`${itemType.label}`" :value="itemType.id" :ripple="false"></v-radio>
                </div>
                </v-radio-group>
            </div>
            <div v-if="!inputMode" class="icp-number-input">
              <InputField
              class="input-custom input-icp-number color-textColor"
              :isTextAlignRight="true"
              :label="$t('icp_target_integration.placeholder_input_icp_number_step')"
              :inputValue="handleGetValueIcp()"
              :isNumberType="true"
              :isPositiveNumber="true"
              @update:inputValue="handleTypeInputICPNumber"
              />
              <span>{{ $t('icp_target_integration.lable_unit_icp_step') }}</span>
            </div>
            <div v-else class="icp-number-input" v-for="year in yearsDuration" :key="year">
              <span class="label-year">{{ $t('icp_target_integration.label_year_icp', {year: year}) }}</span>
              <InputField
              :isNumberType="true"
              :isPositiveNumber="true"
              class="input-custom input-icp-number color-textColor"
              :label="$t('icp_target_integration.placeholder_input_icp_number_step')" 
              :year="year"
              :isTextAlignRight="true"
              @update:inputValue="handleTypeInputICPNumber"
              :inputValue="handleGetValueInEachYear(year)"
              />
              <span>{{ $t('icp_target_integration.lable_unit_icp_step') }}</span>
            </div>
            <div class="right-site-plus">
              <CommonButton @action="handleShowPopup" :label="$t('icp_target_integration.button_show_popup_ref_icp_step')" class="no-hover-sp btn-redution-es">
                <img src="@/assets/images/dialog/info.svg" class="mr-2 icon" />
                <img src="@/assets/images/dialog/info_active.svg" class="mr-2 icon-active" />
              </CommonButton>
          </div>
      </div>
      <usage-guidelines-icp
        :dataTable="dataTable"
        :dialog="dialogPopup"
        @close="dialogPopup=false"
      >
      </usage-guidelines-icp>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import usageGuidelinesIcp from '@/components/dialogs/usageGuidelinesIcp';
import { getDataRefGuidelinesICP } from "@/api/reduction-target";

// import 
export default {
    name: '',
    props: {
      dataSetting: {
        type: Object,
        default: () => {}
      }
    },
    data() {
        return {
            isStickyLeftMenu: false,
            activeIndex: 0,
            menus: [
                {
                    text: this.$t('register_reduction.title_form_target_emission'),
                    id: 0
                }
            ],
            inputMode: 0,
            itemsTypeInput: [
                {
                    id: 0,
                    label: this.$t('icp_target_integration.input_all_duration_mode_icp_step') 
                },
                {
                    id: 1,
                    label: this.$t('icp_target_integration.input_each_year_in_duration_mode_icp_step') 
                }
            ],
            dialogPopup: false,
            icpObject: {},
            icpObjectEachYear: {},
            nextStepFlag: false,
            dataTable: []
        }
    },
    components: { InputField, CommonButton,  usageGuidelinesIcp},
    computed: {
        ...mapState('commonApp', ['isExpand']),
        yearsDuration() {
          let years = [];
          for(let year = this.dataSetting.startAt; year <= this.dataSetting.endAt; year ++) {
            years.push(year);
          }
          return years;
        }
    },
    watch: {
      // NOTE: REVERT ICP value required
      // nextStepFlag(value) {
      //   this.$emit('updateCanNextStep', value);
      // },
      // icpObjectEachYear(value) {
      //   if(this.inputMode) {
      //     this.validateIcpValue(value);
      //   }
      // },
      // icpObject(value) {
      //   if(!this.inputMode) {
      //     this.validateIcpValue(value);
      //   }
      // }
    },
    methods: {
      handleTypeInputICPNumber(value, index, year) {
        if(this.inputMode) {
          if(year) {
            this.icpObjectEachYear = {...this.icpObjectEachYear, [year]: value}
          }
        } else {
          this.yearsDuration.forEach(item => {
            this.icpObject = {...this.icpObject, [item]: value}
          });
        }
        // NOTE: REVERT ICP value required
        const dataEmit = {
          type: this.inputMode,
          value_icps: this.inputMode ? this.icpObjectEachYear : this.icpObject
        }
        this.$emit('getIcpSettingValue', dataEmit);
      },
      handleShowPopup() {
        this.dialogPopup = true
      },
      handleGetValueInEachYear(year) {
        return this.icpObjectEachYear[year] ?? "";
      },
      handleGetValueIcp() {
        return Object.values(this.icpObject).length ? Object.values(this.icpObject)[0] : '';
      },
      validateIcpValue(icpValue) {
        this.nextStepFlag = this.yearsDuration.every(item => icpValue.hasOwnProperty(item) && icpValue[item]);

        if (this.nextStepFlag) {
          this.$emit('getIcpSettingValue', icpValue);
        }

        return this.nextStepFlag;
      }
    },
    created() {
      const settingIcp =  this.dataSetting.setting_icp
      const settingIcpType =  settingIcp && this.dataSetting.setting_icp.type
      this.inputMode = settingIcpType ? 1: 0;
      // incase back data: settingIcp.value_icps as object
      this.icpObject = settingIcp && !settingIcpType ? typeof settingIcp.value_icps === 'object' ? settingIcp.value_icps : JSON.parse(settingIcp.value_icps) : {}
      this.icpObjectEachYear = settingIcpType ? typeof settingIcp.value_icps === 'object' ? settingIcp.value_icps : JSON.parse(settingIcp.value_icps) : {};
      // NOTE: REVERT ICP value required
      this.$emit('updateCanNextStep', true);
      getDataRefGuidelinesICP().then((res) => {
        this.dataTable = res
      });
    }
}
</script>
<style lang="scss" scoped>
.icp-step {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
  .left-site {
    display: none;
  }
  .right-site {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: $bgCusLight;

    h3 {
      color: $goldMid;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 175% */
      letter-spacing: 1.6px;
    }
    .input-icp-mode {
        .text-des {
          color: $monoBlack;

          /* Labels/Medium */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.42px;
          padding-bottom: 2px;
          margin-bottom: 16px;
        }
        ::v-deep .v-input__control

        .v-input__slot {
          .v-input--radio-group__input .radio-part {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .v-radio {
            display: flex;
            align-items: flex-start;
            label {
                color: $monoBlack;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 171.429% */
                padding-bottom: 2px;
                letter-spacing: 0.42px;
              }
            .v-input--selection-controls__input {
              margin-top: 5px !important;
            }
          }

          .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0px;
          }
        }
      }
    .icp-number-input {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: column;
      gap: 8px 16px;
      .input-icp-number {
        flex: 1;
        width: 180px;
        min-width: 180px;
        ::v-deep .v-input.field .v-input__control .v-input__slot input {
          text-align: right;
          padding-right: 16px;
        }
        ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
          padding: 0px;
          padding-left: 32px;
        }
      }
      .label-year {
        flex-basis: 100%;
      }
      
      span {
        flex: 1;
        display: flex;
        align-items: center;
        color: $monoBlack;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.42px;
      }
    }
    &-plus {
      .common-btn {
        width: 100%;
        .v-btn__content {
          .icon {
            display: block;
          }
          .icon-active {
            display: none;
          }
        }
        &:hover {
          .v-btn__content {
            .icon-active {
              display: block;
            }
            .icon {
              display: none;
            }
          }
        }
    }
  }
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .icp-step {
    &.sticky-menu {
      .left-site {
        width: 220px;
        left: calc(101px + 190px);
        &.no-expand {
          left: calc(101px + 105px);
        }
      }
    }
  }
}

@include desktop {
  .icp-step {
    display: flex;
    flex-direction: row;
    gap: 48px;
    .left-site {
      display: block;
      width: 240px;
      min-width: 240px;
      // position: sticky;
      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 8px;
        padding: 8px 10px;
        border-bottom: 2px solid rgba(121, 134, 134, 0.12);
        cursor: pointer;
        &__label {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.42px;
          color: $monoBlack;
          max-width: 192px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;
        }

        &:hover {
          border-bottom: 2px solid $blueMid;
        }

        &.item-active {
          border-bottom: 2px solid $blueMid;
        }
      }
    }
    .right-site {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: calc((100% - 240px - 48px));
      min-width: calc((100% - 240px - 48px));
      .input-icp-mode {
        .text-des {
          padding-bottom: 2px;
          margin-bottom: 8px;
        }
        ::v-deep .v-input__control

        .v-input__slot {
          .v-input--radio-group__input .radio-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
          }
        }
      }
      .icp-number-input {
        display: flex;
        flex-wrap: unset;
        flex-direction: row;
        gap: 16px;
        .label-year {
          flex-basis: unset;
        }
        .input-icp-number, span {
          flex: unset;
        }
      }
    }
    &.sticky-menu {
      gap: 0;
      .left-site {
        position: fixed;
        top: 128px;
      }
      .right-site {
        margin-left: 288px;
      }
    }
  }
}

</style>

