<template>
  <div class="reduction-settings list-menu">
    <div class="listMenus-btn">
      <h2 class="reduction-settings-title mb-6">{{ $t("register_reduction.title_form_information") }}</h2>
      <div class="title-btn">
        <span>{{ $t("register_reduction.label_input_title") }}</span>
        <div class="title-require">{{ $t("register_reduction.label_required") }}</div>
      </div>
      <InputField
        :label="labelTitleInput"
        class="mt-2 input-custom color-textColor"
        :inputValue="title"
        @update:inputValue="handleUpdateTitle"
        />

      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>

      <div class="title-btn mb-2">
        <span>{{ $t("register_reduction.label_input_subject") }}</span>
        <div class="title-require">{{ $t("register_reduction.label_required") }}</div>
      </div>
      <div class="filter listMenu-btn filter-organization">
        <ExportPulldown
          type="organizational"
          :key="reRenderKey.organizational"
          :disabled="isDisableInput"
          :dataList="dataList.organizational"
          :selectedData="dataExport.organizational"
          :itemCount="itemsCount['organizational']"
          :placeholder="placeholderOrganizationCategory"
          :arrowDownBtn="true"
          :isReduction="true"
          :getAllText="textAllOrganizational"
          @updateData="updateData"
        />
      </div>
      <div class="filter filter-methods mt-4">
        <CheckboxPopup
          type="companies"
          :key="reRenderKey.companies"
          :disabled="isDisableInput"
          :dataList="dataList.companies"
          :selectedData="dataExport.companies"
          :itemCount="itemsCount['companies']"
          :placeholder="placeholderSelectCompany"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="textAllCompanies"
          :searchPlacehoder="placeholderSearchCompany"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('business_name')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="business"
          :key="reRenderKey.business"
          :disabled="isDisableInput"
          :dataList="dataList.business"
          :selectedData="dataExport.business"
          :itemCount="itemsCount['business']"
          :placeholder="getPlaceholderLayerName('business_name')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['business']"
          :searchPlacehoder="placeholderSearchLayers['business']"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('country')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="countries"
          :key="reRenderKey.countries"
          :disabled="isDisableInput"
          :dataList="dataList.countries"
          :selectedData="dataExport.countries"
          :itemCount="itemsCount['countries']"
          :placeholder="getPlaceholderLayerName('country')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['countries']"
          :searchPlacehoder="placeholderSearchLayers['countries']"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('layer_3')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="layer3"
          :key="reRenderKey.layer3"
          :disabled="isDisableInput"
          :dataList="dataList.layer3"
          :selectedData="dataExport.layer3"
          :itemCount="itemsCount['layer3']"
          :placeholder="getPlaceholderLayerName('layer_3')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['layer3']"
          :searchPlacehoder="placeholderSearchLayers['layer3']"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('layer_4')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="layer4"
          :key="reRenderKey.layer4"
          :disabled="isDisableInput"
          :dataList="dataList.layer4"
          :selectedData="dataExport.layer4"
          :itemCount="itemsCount['layer4']"
          :placeholder="getPlaceholderLayerName('layer_4')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['layer4']"
          :searchPlacehoder="placeholderSearchLayers['layer4']"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('layer_5')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="layer5"
          :key="reRenderKey.layer5"
          :disabled="isDisableInput"
          :dataList="dataList.layer5"
          :selectedData="dataExport.layer5"
          :itemCount="itemsCount['layer5']"
          :placeholder="getPlaceholderLayerName('layer_5')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['layer5']"
          :searchPlacehoder="placeholderSearchLayers['layer5']"
          @updateData="updateData"
        />
      </div>
      <div v-if="listLayersVisible.includes('layer_6')" class="filter filter-methods mt-4">
        <CheckboxPopup
          type="layer6"
          :key="reRenderKey.layer6"
          :disabled="isDisableInput"
          :dataList="dataList.layer6"
          :selectedData="dataExport.layer6"
          :itemCount="itemsCount['layer6']"
          :placeholder="getPlaceholderLayerName('layer_6')"
          :hasSearch="true"
          :isReduction="true"
          :selectedCompany="true"
          :getAllText="selectAllTextLayers['layer6']"
          :searchPlacehoder="placeholderSearchLayers['layer6']"
          @updateData="updateData"
        />
      </div>
      <div class="filter filter-methods mt-4">
        <CheckboxPopup
          type="listScopeCategories"
          :disabled="isDisableInput"
          :key="reRenderKey.methods"
          :dataList="dataList.methods"
          :selectedData="dataExport.listScopeCategories"
          :itemCount="methodsCount"
          :placeholder="placeholderScopeCategory"
          :showFullText="true"
          @updateData="updateData"
        />
      </div>

      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <!-- duration -->

      <div class="listMenu-btn">
        <div class="listMenu-btn-text option-duration" :class="isOverText && 'option-select-year'">
          <div class="item-duration">
            <div class="title-btn mb-2">
              <span>{{ $t("register_reduction.label_reference_year") }}</span>
              <div class="title-require">{{ $t("register_reduction.label_required") }}</div>
            </div>
            <v-select
              solo
              flat
              :items="getYearList"
              v-model="dataExport.startAt"
              :disabled="isDisableInput"
              item-text="text"
              item-value="value"
              :label="placeholderInputYear"
              class="select-type list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu export-select' }"
            />
          </div>

          <div class="item-duration">
            <div class="title-btn mb-2">
              <span>{{ $t("register_reduction.label_achievement_year") }}</span>
              <div class="title-require">{{ $t("register_reduction.label_required") }}</div>
            </div>
            <v-select
              solo
              flat
              :items="getYearList"
              v-model="dataExport.endAt"
              :disabled="isDisableInput"
              item-text="text"
              item-value="value"
              :label="placeholderInputYear"
              class="select-type list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu export-select' }"
            />
          </div>
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn">
        <span>{{ $t("register_reduction.label_input_note") }}</span>
      </div>
      <InputField
        :label="placeholderInputNote"
        class="mt-2 input-custom color-textColor"
        :inputValue="note"
        @update:inputValue="handleUpdateNote"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PageTitle from "@/components/pageTitle/pageTitle";
import CommonButton from "@/components/utils/button.vue";
import { getDurations } from "@/api/duration";
import { scopeCategoriesData, oldmethod, newPcafMethod } from '@/constants/export-report';
import ExportPulldown from '@/components/products/reductions/dialogs/CheckboxPullDown';
import CheckboxPopup from '@/components/products/reductions/dialogs/CheckboxPopup';
import { getListDetailBranch } from '@/api/registerData';
import {getScopesAndCategories} from '@/api/emissionCalculations';
import moment from "moment";
import InputField from '@/components/products/input/InputField.vue';
import { prepareBranchData } from '@/utils/registerData';
import { TEXT_NULL } from "@/constants/dashboard";
import { setDataTypeForPcafMethod } from '@/utils/pcaf';
import { getDataTypeApi } from '@/api/pcaf'

export default {
  name: "ScopeProductSelect",
  components: {
    PageTitle,
    CommonButton,
    ExportPulldown,
    InputField,
    CheckboxPopup
  },
  props: {
    dataSetting: {
      type: Object,
      default: {}
    },
    isDisableInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: null,
      note: null,
      monthList           : [],
      errorDialog                : false,
      dataExport: {
        startAt: new Date().getFullYear(),
        endAt: null,
        methodExport: 1,
        organizational: [],
        companies: [],
        methods: [],
        business: [],
        countries: [],
        layer3: [],
        layer4: [],
        layer5: [],
        layer6: [],
        listScopeCategories: [],
      },
      methodsExport: [
        { value: 1, text: '排出量レポートを出力' },
        { value: 2, text: 'スコープ/カテゴリ毎の登録済みデータ一覧を出力' },
      ],
      monthList: [],
      dataList: {
        organizational: [],
        companies: [],
        methods: scopeCategoriesData,
        business: [],
        countries: [],
        layer3: [],
        layer4: [],
        layer5: [],
        layer6: [],
      },
      selectedDuration: null,
      selectedYearDuration: null,
      errorMessage: "",
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
        business: 0,
        countries: 0,
        layer3: 0,
        layer4: 0,
        layer5: 0,
        layer6: 0,
      },
      currentSelectMonth: '',
      methodsCount: 100,
      branchData: {},
      selectedFirst : null,
      filterTypes : [
        'organizational',
        'companies',
      ],
      listLayersVisible: [],
      listLayersName: {},
      layerObjectByField: {},
      allDataType: []
    };
  },
  computed: {
    ...mapActions("newRegisterData", ["isNextTab"]),
    ...mapState("userData", ["isSettingPcaf", "planType", "contractor"]),
    isValidContent() {
      const isHasCompany = this.dataExport?.companies?.length > 0
      const isHasScope = this.dataExport?.listScopeCategories?.length > 0
      let isHasLayer = true;
      
      // check layer when is not integrated target
      if (!this.isDisableInput) { 
        const convertKeys = {
          business_name: 'business',
          country: 'countries',
          layer_3: 'layer3',
          layer_4: 'layer4',
          layer_5: 'layer5',
          layer_6: 'layer6',
        };

        const dataArr = this.listLayersVisible
          .filter(item => this.dataExport[convertKeys[item]].length)
          .map(item => this.dataExport[convertKeys[item]]);

        isHasLayer = dataArr.length === this.listLayersVisible.length;
      }
      return (this.title && this.dataExport?.organizational?.length > 0 && (this.dataExport.startAt <= this.dataExport.endAt) && isHasCompany && isHasScope && isHasLayer) ? true : false
    },
    isValidDuration() {
      return (this.dataExport.startAt && this.dataExport.endAt && this.dataExport.startAt <= this.dataExport.endAt ) ? true : false
    },
    getYearList() {
      let dataYears = []
      const startAt = 2020, endAt = 2030;
      if(!startAt || !endAt) return
      for (let index = endAt; index >= startAt; index--) {
        dataYears.push(index);
      }
      return dataYears.map(item => {
        return {
          text:  this.$t("register_reduction.fiscal_year", { year : item}),
          value: item
        }
      })
    },
    isOverText() {
      // 7 characters is over
      if((this.$t("register_reduction.label_reference_year") .length + this.$t("register_reduction.label_required").length) > 7
      || (this.$t("register_reduction.label_achievement_year") .length + this.$t("register_reduction.label_required").length) > 7
      ) {
        return true;
      }
      return false
    },
    getStartYearList() {
      let dataYears = []
      const startAt = this.dataExport.startAt, endAt = this.dataExport.endAt;
      if(!startAt || !endAt) return

      for (let index = startAt; index <= endAt; index++) {
        dataYears.push(index);
      }
      return dataYears.map(item => {
        return {
          text:  this.$t("register_reduction.fiscal_year", { year : item}),
          value: item
        }
      })
    },
    getEndYearList() {
      let dataYears = []
      const startAt = 2020, endAt = 2030;
      if(!startAt || !endAt) return

      for (let index = startAt; index <= endAt; index++) {
        dataYears.push(index);
      }
      return dataYears.map(item => {
        return {
          text: this.$t("register_reduction.fiscal_year", { year : item}),
          value: item
        }
      })
    },
    labelTitleInput() {
      return this.$t("register_reduction.placeholder_input_title");
    },
    textAllOrganizational() {
      return this.$t("register_reduction.pulldown_input_organizational");
    },
    textAllCompanies() {
      return this.$t("register_reduction.label_input_legal");
    },
    placeholderScopeCategory() {
      return this.$t("register_reduction.placeholder_input_scope_category");
    },
    placeholderInputNote() {
      return this.$t("register_reduction.placeholder_input_note");
    },
    placeholderInputYear() {
      return this.$t("register_reduction.placeholder_input_year");
    },
    placeholderOrganizationCategory() {
      return this.$t('register_reduction.placeholder_organization_category')
    },
    placeholderSelectCompany() {
      return this.$t('register_reduction.placeholder_select_company')
    },
    placeholderSearchCompany() {
      return this.$t('register_reduction.placeholder_search_legal')
    },
    itemsCount() {
      return {
        'organizational': this.dataList.organizational.length - 1,
        'companies': this.dataList.companies.length - 1,
        'business': this.dataList.business.length - 1,
        'countries': this.dataList.countries.length - 1,
        'layer3': this.dataList.layer3.length - 1,
        'layer4': this.dataList.layer4.length - 1,
        'layer5': this.dataList.layer5.length - 1,
        'layer6': this.dataList.layer6.length - 1,
      }
    },
    getPlaceholderLayerName() {
      return (layerFiled) => {
        const layerName = this.layerObjectByField[layerFiled]?.layer_name
        return  this.$t('icp_target_integration.title_name_layer', { name: layerName });
      };
    },
    selectAllTextLayers() {
      return {
        'business': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['business_name']}),
        'countries': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['country']}),
        'layer3': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['layer_3']}),
        'layer4': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['layer_4']}),
        'layer5': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['layer_5']}),
        'layer6': this.$t('register_reduction.label_input_layer', {layer: this.listLayersName['layer_6']}),
      }
    },
    placeholderSearchLayers() {
      return {
        'business': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['business_name']}),
        'countries': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['country']}),
        'layer3': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['layer_3']}),
        'layer4': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['layer_4']}),
        'layer5': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['layer_5']}),
        'layer6': this.$t('register_reduction.placeholder_search_layer', {layer: this.listLayersName['layer_6']}),
      }
    }
  },
  async mounted() {
    if(this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    await this.getDuration();
    await this.getScopeStatus();
    await this.prepareData();

    const indexScope3Category15 = scopeCategoriesData.findIndex(item => item.categories === 15)
    let dataScope = scopeCategoriesData
    dataScope.forEach(item => {
      if(item.selectAll) {
        item.selectAll = false
      }
      item?.methods?.forEach(itemlayer1 => {
        if(itemlayer1.selectAll) {
          itemlayer1.selectAll = false
        }
      })
    })
    const pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
    dataScope[indexScope3Category15].methods = [...oldmethod, ...pcafMethod]
    if(this.planType.is_pcaf) {
      dataScope[indexScope3Category15].methods = pcafMethod
    } else {
      dataScope[indexScope3Category15].methods = oldmethod
    }    
    this.dataList.methods = dataScope
    this.methodsCount = this.getAllMethods().length;
    if(this.isOneObjectPropertyNonUndefined(this.dataSetting)) {
      // get all organizational data
      this.dataList.organizational.push({
        text: this.$t('register_reduction.pulldown_input_organizational'),
        hasCheckbox: true,
      })
      this.branchData?.organizational_division?.filter(item => item.status === true)?.forEach(item => {
        this.dataList.organizational.push({
          text: item.value,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.key
        })
      });
      this.dataExport = {
        ...this.dataSetting,
        companies: [...new Set(this.dataSetting.companies)],
        business: [...new Set(this.dataSetting.business)],
        countries: [...new Set(this.dataSetting.countries)],
        layer3: [...new Set(this.dataSetting.layer3)],
        layer4: [...new Set(this.dataSetting.layer4)],
        layer5: [...new Set(this.dataSetting.layer5)],
        layer6: [...new Set(this.dataSetting.layer6)],
      }
      this.title = this.dataSetting.title
      this.note = this.dataSetting.note
      this.removeSelectedMethodsData();
      this.addFilterIndex('organizational');
      this.addFilterIndex('companies');
      if (this.listLayersVisible.includes('business_name')) this.addFilterIndex('business');
      if (this.listLayersVisible.includes('country')) this.addFilterIndex('countries');
      if (this.listLayersVisible.includes('layer_3')) this.addFilterIndex('layer3');
      if (this.listLayersVisible.includes('layer_4')) this.addFilterIndex('layer4');
      if (this.listLayersVisible.includes('layer_5')) this.addFilterIndex('layer5');
      if (this.listLayersVisible.includes('layer_6')) this.addFilterIndex('layer6');
      this.updateData('organizational', this.dataExport.organizational); // handle get layer list data
      if (this.listLayersVisible.includes('business_name')) this.setDefaultLayerDataInDetail('business');
      if (this.listLayersVisible.includes('country')) this.setDefaultLayerDataInDetail('countries');
      if (this.listLayersVisible.includes('layer_3')) this.setDefaultLayerDataInDetail('layer3');
      if (this.listLayersVisible.includes('layer_4')) this.setDefaultLayerDataInDetail('layer4');
      if (this.listLayersVisible.includes('layer_5')) this.setDefaultLayerDataInDetail('layer5');
      if (this.listLayersVisible.includes('layer_6')) this.setDefaultLayerDataInDetail('layer6');
    } else {
      this.defaultData();
    }
    this.reRenderKey.organizational++;
    this.reRenderKey.companies++;
    if (this.listLayersVisible.includes('business_name')) this.reRenderKey.business++;
    if (this.listLayersVisible.includes('country')) this.reRenderKey.countries++;
    if (this.listLayersVisible.includes('layer_3')) this.reRenderKey.layer3++;
    if (this.listLayersVisible.includes('layer_4')) this.reRenderKey.layer4++;
    if (this.listLayersVisible.includes('layer_5')) this.reRenderKey.layer5++;
    if (this.listLayersVisible.includes('layer_6')) this.reRenderKey.layer6++;
  },
  watch: {
    'dataExport.startAt': {
      handler(newVal) {
        this.$emit('updateStartYear', newVal)
      },
      deep: true
    },
    'dataExport.endAt': {
      handler(newVal) {
        this.$emit('updateEndYear', newVal)
      },
      deep: true
    },
    isValidContent: {
      handler(newVal) {
        this.$emit('updateCanNextStep', newVal)
        this.getBranchIdByFilterData();
      }
    },
    dataExport: {
      handler() {
        if(this.$route.query?.id) {
          this.$emit("getDataLast", {...this.dataExport, title: this.title, note: this.note})
        }
      },
      deep: true
    },
    title() {
      if(this.$route.query?.id) {
        this.$emit("getDataLast", {...this.dataExport, title: this.title, note: this.note})
      }
    },
    note() {
      if(this.$route.query?.id) {
        this.$emit("getDataLast", {...this.dataExport, title: this.title, note: this.note})
      }
    }
  },
  methods: {
    defaultData() {
      this.dataList.organizational.push({
        text: this.$t('register_reduction.pulldown_input_organizational'),
        hasCheckbox: true,
      })
      this.branchData?.organizational_division?.filter(item => item.status === true)?.forEach(item => {
        this.dataList.organizational.push({
          text: item.value,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.key
        })
        if (!this.dataExport?.id) {
          this.dataExport.organizational.push(item.value)
        }
      });
      this.dataList.companies.push({
        text: this.$t('register_reduction.label_input_legal'),
        hasCheckbox: true,
      })
      this.branchData?.company_name?.filter(item => item.status === true)?.forEach(item => {
        this.dataList.companies.push({
          text: item.value,
          hasCheckbox: true,
        })
        if (!this.dataExport?.id) {
          this.dataExport.companies.push(item.value)
        }
      });
      this.addFilterIndex('organizational');
      this.addFilterIndex('companies');
      if (this.listLayersVisible.includes('business_name')) {
        this.branchData?.business_name?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.business.push(item.value)
        })
        this.addFilterIndex('business');
      }
      if (this.listLayersVisible.includes('country')) {
        this.branchData?.country?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.countries.push(item.value)
        })
        this.addFilterIndex('countries');
      };
      if (this.listLayersVisible.includes('layer_3')) {
        this.branchData?.layer_3?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.layer3.push(item.value)
        })
        this.addFilterIndex('layer3');
      };
      if (this.listLayersVisible.includes('layer_4')) {
        this.branchData?.layer_4?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.layer4.push(item.value)
        })
        this.addFilterIndex('layer4');
      };
      if (this.listLayersVisible.includes('layer_5')) {
        this.branchData?.layer_5?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.layer5.push(item.value)
        })
        this.addFilterIndex('layer5');
      };
      if (this.listLayersVisible.includes('layer_6')) {
        this.branchData?.layer_6?.filter(item => item.status === true)?.forEach(item => {
          this.dataExport.layer6.push(item.value)
        })
        this.addFilterIndex('layer6');
      };
      this.updateData('organizational', this.dataExport.organizational);
      this.$emit('getDataList', this.dataList);
    },
    isOneObjectPropertyNonUndefined(object) {
      return Object.values(object).some(v => v !== undefined)
    },
    formatDuration(time) {
      return moment(time, 'YYYY-M').format('YYYY-MM');
    },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    async getScopeStatus() {
      await getScopesAndCategories({contractor_id: this.contractor})
        .then((response) => {
          let isEnableScope3 = response.find((object) => {
            return object.hasOwnProperty('display_scope_3')
          })
          this.dataList.methods = isEnableScope3.display_scope_3 === true ? scopeCategoriesData : scopeCategoriesData.filter(method => method.scope !== 3);
          this.reRenderKey.methods++;
        })
        .catch(() => {
        });
    },
    async getDuration() {
      await getDurations().then((res) => {
        const selectedDuration = res.data.find((item) => item.is_selected === true);
        if (selectedDuration) {
          this.selectedDuration = selectedDuration.id;
          this.selectedYearDuration = selectedDuration.year;
          this.getMonthList(selectedDuration.start_at, selectedDuration.end_at);
        }
      });
    },
    getMonthList(startAt, endAt) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat = durationStartAt.toLocaleString('ja-JP', { month: 'long', year: 'numeric' });
      this.monthList = [
        {
          text: timeFormat,
          value: durationStartAt.getMonth() + 1,
        },
      ];

      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = durationStartAt.toLocaleString('ja-JP', { month: 'long', year: 'numeric' });
        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1,
        };
        this.monthList.push(currentMonthYear);
      }
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.indexOf(type);
      if (isRemove && existIndex >= 0) {
        let removeItemsCount = 0;
        for (let i = existIndex; i < this.filterIndex.length; i++) {
          this.dataExport[this.filterIndex[i]] = [];
          removeItemsCount += 1;
        }
        this.filterIndex.splice(existIndex, removeItemsCount);
      }
      if (existIndex < 0 && !isRemove) {
        this.filterIndex.push(type);
      }
    },
    updateFilter(type) {
      if (this.filterIndex.length === 0) {
        this.updateGetAllData();
        this.selectedFirst = null;
        this.$emit('update', [], false);
        return;
      }
      this.filterCondition(type);
      if (!this.selectedFirst) {
        this.selectedFirst = type;
      }
      // get branch id
      this.getBranchIdByFilterData();
    },
    updateGetAllData() {
      this.resetFilter();
      this.branchData.filterPatternList.forEach((item) => {
        this.addOrgani(item);
        this.addCompany(item);
        this.addBusiness(item);
        this.addCountry(item);
        this.addLayer3(item);
        this.addLayer4(item);
        this.addLayer5(item);
        this.addLayer6(item);
      });
      Promise.all([
        this.removeDuplicateData(this.dataList.organizational, 'organizational'),
        this.removeDuplicateData(this.dataList.companies, 'companies'),
        this.removeDuplicateData(this.dataList.business, 'business'),
        this.removeDuplicateData(this.dataList.countries, 'countries'),
        this.removeDuplicateData(this.dataList.layer3, 'layer3'),
        this.removeDuplicateData(this.dataList.layer4, 'layer4'),
        this.removeDuplicateData(this.dataList.layer5, 'layer5'),
        this.removeDuplicateData(this.dataList.layer6, 'layer6'),
      ]);
      this.resetSelectedData();
    },
    addOrgani(item) {
      if (this.selectedFirst === "organizational") return;
      this.dataList.organizational.push({
        text: item.organizational_division_value,
        hasCheckbox: true,
        branch_ids: item.branch_ids,
        id: item.organizational_division,
      })
    },
    addCompany(item) {
      if (this.selectedFirst === "companies") return;
      this.dataList.companies.push({
        text: item.company_name,
        hasCheckbox: true,
      })
    },
    addBusiness(item) {
      if (this.selectedFirst === "business") return;
      this.dataList.business.push({
        text: item.business_name,
        hasCheckbox: true,
      })
    },
    addCountry(item) {
      if (this.selectedFirst === "countries") return;
      this.dataList.countries.push({
        text: item.country,
        hasCheckbox: true,
      })
    },
    addLayer3(item) {
      if (this.selectedFirst === "layer3") return;
      this.dataList.layer3.push({
        text: item.layer_3,
        hasCheckbox: true,
      })
    },
    addLayer4(item) {
      if (this.selectedFirst === "layer4") return;
      this.dataList.layer4.push({
        text: item.layer_4,
        hasCheckbox: true,
      })
    },
    addLayer5(item) {
      if (this.selectedFirst === "layer5") return;
      this.dataList.layer5.push({
        text: item.layer_5,
        hasCheckbox: true,
      })
    },
    addLayer6(item) {
      if (this.selectedFirst === "layer6") return;
      this.dataList.layer6.push({
        text: item.layer_6,
        hasCheckbox: true,
      })
    },
    filterCondition(type) {
      if (this.filterIndex.length === 0) return;
      this.resetFilter(type, true);
      let types = [];
      let filterDataList = [];
      const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
      for (let i = 0; i <= filterIndexLength; i++) {
        filterDataList.push(this.filterIndex[i]);
      }
      types = this.filterTypes.filter((item) => !filterDataList.includes(item)); // get elements not in filterTypes
      types.forEach((itemType) => {
        for (let i = 0; i < this.branchData.filterPatternList.length; i++) {
          if (!this.checkItemExist(itemType, this.branchData.filterPatternList[i])) continue;
          switch (itemType) {
            case "organizational":
              this.addOrgani(this.branchData.filterPatternList[i]);
            break;
            case "companies":
              this.addCompany(this.branchData.filterPatternList[i]);
              break;
            case "business":
              this.addBusiness(this.branchData.filterPatternList[i]);
              break;
            case "countries":
              this.addCountry(this.branchData.filterPatternList[i]);
              break;
            case "layer3":
              this.addLayer3(this.branchData.filterPatternList[i]);
              break;
            case "layer4":
              this.addLayer4(this.branchData.filterPatternList[i]);
              break;
            case "layer5":
              this.addLayer5(this.branchData.filterPatternList[i]);
              break;
            case "layer6":
              this.addLayer6(this.branchData.filterPatternList[i]);
              break;
          }
        }
        this.resetSelectedData(itemType);
      });
      
      Promise.all([
        this.removeDuplicateData(this.dataList.organizational, 'organizational'),
        this.removeDuplicateData(this.dataList.companies, 'companies'),
        this.removeDuplicateData(this.dataList.business, 'business'),
        this.removeDuplicateData(this.dataList.countries, 'countries'),
        this.removeDuplicateData(this.dataList.layer3, 'layer3'),
        this.removeDuplicateData(this.dataList.layer4, 'layer4'),
        this.removeDuplicateData(this.dataList.layer5, 'layer5'),
        this.removeDuplicateData(this.dataList.layer6, 'layer6'),
      ]);
    },
    resetSelectedData(type) {
      if (!type) {
        this.filterTypes.forEach(key => {
          this.dataExport[key] = [];
          this.reRenderKey[key]++;
        })
        return;
      }
      let dataList = [...this.dataList[type]?.map(item => item.text)];
      [...this.dataExport[type]]?.forEach((item) => {
        if (!dataList.includes(item)) {
          const index = this.dataExport[type].indexOf(item)
          this.dataExport[type].splice(index, 1);
        }
      })
      this.reRenderKey[type]++
    },
    checkItemExist(itemType, item) {
      let existIndex = this.filterIndex.indexOf(itemType);
      existIndex = existIndex < 0 ? this.filterIndex.length : existIndex;
      for (let i = 0; i < existIndex; i++){
        if (!this.filterDataByIndex(this.filterIndex[i], item)) {
          return false;
        }
      }
      return true;
    },
    resetFilter(type, isRemove = false) { 
      let items = [];
      if (this.filterIndex.length === 0) {
        items = [
          'organizational',
          'companies',
          'business',
          'countries',
          'layer3',
          'layer4',
          'layer5',
          'layer6'
        ];
      } else {
        const filterIndexLength = this.filterIndex.indexOf(type) < 0 ? this.filterIndex.length - 1 : this.filterIndex.indexOf(type);
        for (let i = 0; i <= filterIndexLength; i++) {
          items.push(this.filterIndex[i]);
        }
      }
      if (isRemove) {
        items = this.filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
      }
      items.forEach((key) => {
        if (key === 'organizational' && this.selectedFirst !== "organizational") {
          this.dataList.organizational = [];
          this.dataList.organizational.push({
            text: this.$t('register_reduction.pulldown_input_organizational'),
            hasCheckbox: true,
          });
        }
        if (key === 'companies' && this.selectedFirst !== "companies") {
          this.dataList.companies = [];
          this.dataList.companies.push({
            text: this.$t('register_reduction.label_input_legal'),
            hasCheckbox: true,
          });
        }
        if (key === 'business' && this.selectedFirst !== "business") {
          this.dataList.business = [];
          this.dataList.business.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
        if (key === 'countries' && this.selectedFirst !== "countries") {
          this.dataList.countries = [];
          this.dataList.countries.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
        if (key === 'layer3' && this.selectedFirst !== "layer3") {
          this.dataList.layer3 = [];
          this.dataList.layer3.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
        if (key === 'layer4' && this.selectedFirst !== "layer4") {
          this.dataList.layer4 = [];
          this.dataList.layer4.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
        if (key === 'layer5' && this.selectedFirst !== "layer5") {
          this.dataList.layer5 = [];
          this.dataList.layer5.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
        if (key === 'layer6' && this.selectedFirst !== "layer6") {
          this.dataList.layer6 = [];
          this.dataList.layer6.push({
            text: this.selectAllTextLayers[key],
            hasCheckbox: true
          });
        }
      });
    },
    filterDataByIndex(type, item) {
      switch (type) {
        case "organizational":
          return this.filterOrganizational(item);
        case "companies":
          return this.filterCompanies(item);
        case "business":
          return this.filterBusiness(item);
        case "countries":
          return this.filterCountries(item);
        case "layer3":
          return this.filterListLayer3(item);
        case "layer4":
          return this.filterListLayer4(item);
        case "layer5":
          return this.filterListLayer5(item);
        case "layer6":
          return this.filterListLayer6(item);
        default:
          return true;
      }
    },
    filterOrganizational(item) {
      return (this.dataExport.organizational.length !== 0 && this.dataExport.organizational.includes(item.organizational_division_value)) || this.dataExport.organizational.length === 0;
    },
    filterCompanies(item) {
      return (this.dataExport.companies.length !== 0 && this.dataExport.companies.includes(item.company_name)) || this.dataExport.companies.length === 0;
    },
    filterBusiness(item) {
      return ((this.dataExport.business.length !== 0 && this.dataExport.business.includes(item.business_name)) ||
      this.dataExport.business.length === 0 ||
      (this.dataExport.business.includes(TEXT_NULL)  && !item.business_name)
      )
    },
    filterCountries(item) {
      return (
        (this.dataExport.countries.length !== 0 && this.dataExport.countries.includes(item.country)) ||
        this.dataExport.countries.length === 0 ||
        (this.dataExport.countries.includes(TEXT_NULL)  && !item.country)
      );
    },
    filterListLayer3(item) {
      return (
        (this.dataExport.layer3.length !== 0 && this.dataExport.layer3.includes(item.layer_3)) ||
        this.dataExport.layer3.length === 0 ||
        (this.dataExport.layer3.includes(TEXT_NULL) && !item.layer_3)
      );
    },
    filterListLayer4(item) {
      return (
        (this.dataExport.layer4.length !== 0 && this.dataExport.layer4.includes(item.layer_4)) ||
        this.dataExport.layer4.length === 0 ||
        (this.dataExport.layer4.includes(TEXT_NULL) && !item.layer_4)
      );
    },
    filterListLayer5(item) {
      return (
        (this.dataExport.layer5.length !== 0 && this.dataExport.layer5.includes(item.layer_5)) ||
        this.dataExport.layer5.length === 0 ||
        (this.dataExport.layer5.includes(TEXT_NULL) && !item.layer_5)
      );
    },
    filterListLayer6(item) {
      return (
        (this.dataExport.layer6.length !== 0 && this.dataExport.layer6.includes(item.layer_6)) ||
        this.dataExport.layer6.length === 0 ||
        (this.dataExport.layer6.includes(TEXT_NULL) && !item.layer_6)
      );
    },
    organiListSort() {
      this.dataList.organizational.sort((a, b) => {
        return a.id - b.id;
      })
    },
    handleUpdateTitle(title) {
      this.title = title
      this.$emit("getDataLast", {
        title: title
      })
    },
    handleUpdateNote(note) {
      this.$emit("getDataLast", {
        note: note
      })
      this.note = note
    },
    removeSelectedMethodsData() {
      // remove selected data in list if not exist
      if (this.dataExport?.listScopeCategories) {
        let scopeCategoriesList = this.getAllMethods();
        let dataList = [];
        scopeCategoriesList.forEach(item => {
          if (this.dataExport?.listScopeCategories.includes(item.text)) {
            dataList.push(item.text);
          }
        });
        this.dataExport.listScopeCategories = dataList;
        this.reRenderKey.methods++;
      }
    },
    getAllMethods() {
      let scopeCategoriesList = []
      this.dataList.methods.forEach(item => {
        if (!item?.methods) {
          scopeCategoriesList.push({
            text: item.text,
            value: item.row_num_pattern,
          });
        } else if (item.methods) {
          item.methods.forEach(itemLayer1 => {
            if (!itemLayer1.methods) {
              scopeCategoriesList.push({
                text: `${item.text}_${itemLayer1.text}`,
                value: itemLayer1.row_num_pattern,
              });
            } else if (itemLayer1.methods) {
              itemLayer1.methods.forEach(itemLayer2 => {
                scopeCategoriesList.push({
                  text: `${item.text}_${itemLayer1.text}_${itemLayer2.text}`,
                  value: itemLayer2.row_num_pattern,
                });
              })
            }
          })
        }
      })
      return scopeCategoriesList;
    },
    async prepareData() {
      const response = await getListDetailBranch()
      this.branchData = prepareBranchData(response)
      response.layer.forEach(item => {
        switch (item.layer_field) {
          case 'business_name':
            this.filterTypes.push('business')
            break;
          case 'country':
            this.filterTypes.push('countries')
            break;
          case 'layer_3':
            this.filterTypes.push('layer3')
            break;
          case 'layer_4':
            this.filterTypes.push('layer4')
            break;
          case 'layer_5':
            this.filterTypes.push('layer5')
            break;
          case 'layer_6':
            this.filterTypes.push('layer6')
            break;
          default:
            break;
        }
        this.listLayersVisible.push(item.layer_field);
        this.layerObjectByField[item.layer_field] = item;
        this.listLayersName[item.layer_field] = item.layer_name;
      })
    },
    updateData(type, data) {
      if (type === 'listScopeCategories') {
        this.dataExport[type] = data.length > 0 ? [...data] : [];
        return;
      }
      if (data.length > 0) {
        this.dataExport[type] = data;
        this.addFilterIndex(type);
        this.updateFilter(type);
      } else {
        // nếu unchecked all data
        this.dataExport[type] = [];
        this.addFilterIndex(type, true);
        this.updateFilter(type);
      }
    },
    removeDuplicateData(arr, type) {
      this.dataList[type] = [...new Map(arr.map((item) => [item['text'], item])).values()];
    },
    getBranchIdByFilterData() {
      const branchIdsList = {
        'organizational': [],
        'companies': [],
        'business': [],
        'countries': [],
        'layer3': [],
        'layer4': [],
        'layer5': [],
        'layer6': [],
      }
      this.branchData.filterPatternList.forEach(item => {
        if (this.dataExport.organizational.includes(item.organizational_division_value)) {
          branchIdsList['organizational'].push(item.id);
        }
        if (this.dataExport.companies.includes(item.company_name)) {
          branchIdsList['companies'].push(item.id);
        }
        if (this.listLayersVisible.includes('business_name') && this.dataExport.business.includes(item.business_name)) {
          branchIdsList['business'].push(item.id);
        }
        if (this.listLayersVisible.includes('country') && this.dataExport.countries.includes(item.country)) {
          branchIdsList['countries'].push(item.id);
        }
        if (this.listLayersVisible.includes('layer_3') && this.dataExport.layer3.includes(item.layer_3)) {
          branchIdsList['layer3'].push(item.id);
        }
        if (this.listLayersVisible.includes('layer_4') && this.dataExport.layer4.includes(item.layer_4)) {
          branchIdsList['layer4'].push(item.id);
        }
        if (this.listLayersVisible.includes('layer_5') && this.dataExport.layer5.includes(item.layer_5)) {
          branchIdsList['layer5'].push(item.id);
        }
        if (this.listLayersVisible.includes('layer_6') && this.dataExport.layer6.includes(item.layer_6)) {
          branchIdsList['layer6'].push(item.id);
        }
      })
      this.$emit("getDataLast", {...this.dataExport, title: this.title, note: this.note, branchIds: branchIdsList})
    },
    setDefaultLayerDataInDetail(type) {
      // set default data if previous layer is get All
      if (this.dataExport[type].length === 0) {
        [...this.dataList[type]].forEach(item => {
          if (item.text !== this.selectAllTextLayers[type]) {
            this.dataExport[type].push(item.text);
          }
        })
      }
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
  }
};
</script>
<style lang="scss">

.select-type {
  &.v-input--is-disabled {
    .v-input__slot {
      background: $monoLight !important;
      .v-select__selections  {
        .v-select__selection--disabled {
          color: $monoMid !important;
        }
      }
    }
  }
}
.reduction-settings {
  &-title {
    color: $goldMid;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}
.select-type .v-input__slot {
  background: $monoOffWhite;
}
.list-menu {
  .select-method {
    &.disabled {
      .v-input__slot {
      background: #E4E6E7 !important;
      input {
        background: unset !important;
      }
    }
    }
  }
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%
}
.listMenu-scope.has-scope  .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope .v-btn__content div{
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
</style>
<style lang="scss" scoped>
.custom-table {
  padding-left: 20px !important;
}
.reduction-settings {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 48px;
    letter-spacing: 0.05em;
    color: $monoBlack;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 20px;
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px 0px;
      img {
        margin-left: 8px;
      }
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      flex-direction: column;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 10px;
      width: 100%;
      height: max-content !important;
      &.has-scope {
        color: $monoBlack;
      }
      &:hover {
        color: $monoWhite;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        // width: 31px;
        height: 20px;
        margin-top: 8px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
    .filter {
      max-width: 100%;
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .input-field{
    ::v-deep .v-input__slot {
      padding: 7px 0px 9px 16px !important;
    }
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }
  .title-btn-select-scope {
    margin-bottom: 12px;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    //padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}
.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;
      .return-active {
        display: none;
      }
    }
  &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
  &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn  {
    width: 100%;
  }
}

.filter-organization {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
.option-duration {
  display: flex;
  flex-flow: row !important;
  justify-content: space-between;
  gap: 24px;
  .item-duration {
    max-width: calc((100% - 16px) / 2);
  }
}

@media screen and (min-width: 360px) and (max-width: 560px) {
  .option-duration {
  &.option-select-year {
    flex-flow: column !important;
    .item-duration {
      width: 100%;
      max-width: unset;
    }
  }
}
}

@include desktop {
  .return__button-responsive {
    display: none;
  }
  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }
  .reduction-settings {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px;

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .submit-register-btn {
      // background: #A9A04B !important;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 48px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }
      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }

    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }

      }
    }
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}
</style>
