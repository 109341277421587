<template>
    <div>
      <v-dialog
        v-model="dialog"
        @click:outside="handleClickOutSite"
        @keydown="handleKeydownClick"
        ref="dialog"
        content-class="title-close fixed-header"
        :max-width="'544px'"
        
      >
      <v-card>
        <v-card-title>
          <span>{{ $t('icp_target_integration.title_usage_guidelines_popup') }}</span>
          <div class="button-close" @click="close">
            <img src="@/assets/images/dialog/close.svg" class="button-close_close" />
            <img src="@/assets/images/dialog/close_active.svg" class="button-close_active" />
          </div>
        </v-card-title>
          <div class="body-area">
            <div class="table-area">
              <div class="table-item" v-for="(item, index) in dataTable" :key="item.id" @click="toggleTable(index)" :class="{'active': openTableIndex === index}">
                <div class="icp_type_name">
                  {{ item.industry }}
                </div>
                <img src="@/assets/images/dialog/dropdown.svg" class="" />
                <div class="table-details" v-if="isTableVisible(index)" v-for="(cell,i) in item.child" :key="i" @click="handleTableDetailsClick($event)">
                  <div class="cell cell-1">{{i + 1}}</div>
                  <div class="cell cell-2">{{ cell.company }}</div>
                  <div class="cell cell-3">{{ cell.set_price }}</div>
                </div>
              </div>
            </div>
            <p>{{ $t('icp_target_integration.description_ref_st_usage_guidelines_popup') }}<span><a href="https://www.env.go.jp/content/000186370.pdf" target="_blank" class="ref_link">{{ $t('icp_target_integration.hyperlink_ref_usage_guidelines_popup') }}</a></span>{{ $t('icp_target_integration.description_ref_rd_usage_guidelines_popup') }}</p>
          </div>
      </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import {KEYS_CODE} from '@/constants/keyboard'
  import CommonButton from '@/components/commonApp/Button.vue';
  
  export default {
    components: {CommonButton},
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      classes: {
        type: String,
        required: false,
      },
      dataTable: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        openTableIndex: []
      };
    },
    computed: {
    },
    methods: {
      close() {
        this.$emit('close');
      },
      handleClickOutSite() {
        this.$emit('close');
      },
      handleKeydownClick(event) {
        if(event.keyCode === KEYS_CODE.ESCAPE) {
          this.close()
        }
      },
      toggleTable(index) {
        if (this.openTableIndex === index) {
          this.openTableIndex = null;
        } else {
          this.openTableIndex = index;
        }
      },
      isTableVisible(index) {
        return this.openTableIndex === index;
      },
      handleTableDetailsClick(event) {
        event.stopPropagation();
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  @import '@/styles/components/popup/index.scss';
  .body-area {
    padding: 16px 32px 16px;
    display: flex;
    gap: 32px;
    flex-direction: column;
    .table-area {
      .table-item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-self: stretch;
        background-color: #F1F9F9;
        border-bottom: 1px solid #2A2A301A;
        box-shadow: 0px 1.577px 1.854px 0px rgba(160, 181, 186, 0.37), 0px 3.791px 4.456px 0px rgba(160, 181, 186, 0.27), 0px 7.137px 8.389px 0px rgba(160, 181, 186, 0.22), 0px 12.732px 14.965px 0px rgba(160, 181, 186, 0.15), 0px 23.813px 27.991px 0px rgba(160, 181, 186, 0.10);
        cursor: pointer;
        .icp_type_name {
          display: flex;
          padding: 6px 6px 6px 8px;
          align-items: flex-start;
          gap: 6px;
          flex: 1 0 0;
          color: $monoBlack;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 153.846% */
        }
        img {
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
        }
        &.active {
          .icp_type_name,
          img {
            background: $bgCusLight;
          }
        }
        .table-details {
          display: flex;
          flex-basis: 100%;
          background-color: $monoOffWhite;
          position: relative;
          z-index: 1;
          cursor: auto;
          border-top: 1px solid #2A2A301A;
          .cell {
            color: $monoBlack;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 3px 8px 5px;
            border-right: 1px solid #2A2A301A;
            &.cell-1 {
              min-width: 32px;
              background: $bgCusLight;
              text-align: center;
              color: $monoDark;
            }
            &.cell-2 {
              width: 260px;
            }
            &.cell-3 {
              flex: 1;
              text-align: right;
            }
          }
          .cell:last-child {
            border-right: none;
          }
        }
      }
    }
    p {
      color: $monoBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      .ref_link {
        color: $goldMid;
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 560px) {
    .body-area {

    .table-area {
      .table-item {
        .table-details {
          .cell {
            &.cell-2 {
              width: 30%;
            }
          }
        }
      }
    }
  }
  }
  </style>
  