export const compareObjects = (obj1, obj2) => {
  for (let key in obj1) {
    if (typeof obj1[key] === "object") {
      if (!compareObjects(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}