<template>
  <div class="compare-emission" :class="isStickyLeftMenu && 'sticky-menu'" ref="scrollContainer">
    <div class="left-site" :class="isExpand ? 'expand' : 'no-expand'">
      <div
        class="left-site-item"
        v-for="(item, i) in reductions"
        :key="i"
        :class="i === activeIndex && 'item-active'"
        @click="handleScrollToItem(i)"
      >
        <div class="left-site-item__label">{{ item.text }}</div>
        <img
          v-if="i === activeIndex"
          src="@/assets/images/reductions/iconAreaActive.svg"
          class="left-site-item__icon"
        />
        <img v-else src="@/assets/images/reductions/iconArea.svg" class="left-site-item__icon mr-2" />
      </div>
    </div>
    <div class="right-site">
      <ReductionItem
        v-for="(item, index) in itemCompares"
        :key="index + key"
        :dataItem="item"
        :index="index"
        :start="dataSetting.startAt"
        :end="dataSetting.endAt"
        :dataSetting="dataSetting"
        :isDisableInput="index === 0 ? isDisableInput :  false"
        @deleteCompareItem="handleShowDeleteCompareItem"
        @updateIsValidItem="handleUpdateIsValidItem"
        @getYearUpdateItem="handleGetYearItem"
        @getYearCompareItem="handleGetYearCompareItem"
        @updateYearVal="handleUpdateYearVal"
        @updateStartYear="handleUpdateStartYear"
        @updateEndYear="handleUpdateEndYear"
        @updateTitle="updateTitle"
        @updateCarbonVal="handleUpdateCarbonVal"
        @updateTypeInput="handleUpdateTypeInput"
        @updateIsInputCarbon ="updateIsInputCarbon"
      />
      <div v-if="itemCompares.length < 4" class="right-site-plus">
        <CommonButton @action="handleAddCompareItem" :label="btnAddTargetCompare" class="no-hover-sp">
          <img src="@/assets/icons/addregistericon.svg" class="mr-2 icon" />
          <img src="@/assets/icons/addregistericon_active.svg" class="mr-2 icon-active" />
        </CommonButton>
      </div>
    </div>
    <QuestionPopup
      :dialog="dialogDeleteCompare"
      :confirm="true"
      :closeText="btnCancelText"
      :message="messageConfirmDelete"
      :confirmText="btnDeleteText"
      :isSaveTemplateButton="false"
      :isCloneTemplate="false"
      @close="dialogDeleteCompare = false"
      classes="register"
      @submit="handleDeleteCompareItem()"
    />
  </div>
</template>

<script>
import ReductionItem from '@/components/products/reductions/ReductionItem.vue';
import CommonButton from '@/components/utils/button.vue';
import QuestionPopup from '@/components/dialogs/question-popup';
import { listRangeYear } from '@/utils/convertNumber'
import { mapActions, mapState } from 'vuex'
import { addThoundSandComma } from '@/utils/convertNumber';
import { calcRangeYearByStartAndEndData, keyYypeInput } from '@/constants/reduction';

export default {
  name: 'CompareEmission',
  components: { ReductionItem, CommonButton, QuestionPopup },
  props: {
    dataSetting: {
      type: Object,
      default: () => {}
    },
    typeInput: {
      type: Number,
      default: 0
    },
    isDisableInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogDeleteCompare: false,
      indexDelete: null,
      startAt: new Date().getFullYear(),
      endAt: 2030,
      indexAdd: 1,
      reductions: [
        {
          text: this.$t('register_reduction.title_form_target_emission'),
          id: 0,
        },
      ],
      areaActive: 1,
      activeIndex: 0,
      lastScrollTop: 0,
      itemCompares: [
        {
          title: this.$t('register_reduction.title_form_target_emission'),
          years: listRangeYear(2020, 2030),
          value: {}
        },
      ],
      isStickyLeftMenu: false,
      itemsHeight: [],
      isValids: [],
      informationYearCompare: [],
      key: 0
    };
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('newRegisterData', ['isNextTab']),

    btnAddTargetCompare() {
      return this.$t("register_reduction.button_add_target_compare");
    },
    btnCancelText() {
      return this.$t("register_reduction.button_cancel");
    },
    btnDeleteText() {
      return this.$t("register_reduction.button_delete");
    },
    messageConfirmDelete() {
      return this.$t("register_reduction.description_message_popup_confirm");
    }
  },
  watch: {
    isNextTab(value) {
      if(value){
        this.$emit('updateYearCompare', this.informationYearCompare);
        this.updateIsNextTab(false)
      }
    },
  },
  mounted() {
    this.startAt = this.dataSetting.startAt
    this.endAt = this.dataSetting.endAt
    this.handleGetCompareYear();
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions("newRegisterData", ["updateIsNextTab"]),
    handleUpdateYearVal(index, year, newVal, type) {
      const key = type ? 'target_emissions_auto' : 'target_emissions';
      let oldEmission = JSON.parse(this.itemCompares[index][key])

      oldEmission[year] = newVal
      this.itemCompares[index][key] = JSON.stringify(oldEmission)
      this.informationYearCompare[index][key] = JSON.stringify(oldEmission)
      this.$emit('updateYearCompareCustome', index, oldEmission, type)
    },
    handleUpdateCarbonVal(index, year, newVal, type) {
      const key = type ? 'carbons_auto' : 'carbons';
      let oldCarbonValue = JSON.parse(this.itemCompares[index][key])
      oldCarbonValue[year] = newVal
      this.itemCompares[index][key] = JSON.stringify(oldCarbonValue)
      this.informationYearCompare[index][key] = JSON.stringify(oldCarbonValue)
      this.$emit('updateCarbonCompareCustome', index, oldCarbonValue, type)
    },
    handleUpdateStartYear(newStartYear, end, index) {
      let endYear = this.itemCompares[index].end_year
      if(index === 0) {
        endYear = this.itemCompares[index].end
      }
      this.updateDataByYear(newStartYear, end, index)
    },
    handleUpdateEndYear(newEndYear, start, index) {
      let startYear = this.itemCompares[index].start_year
      if(index === 0) {
        startYear = this.itemCompares[index].start
      }
      this.updateDataByYear(start, newEndYear, index)
    },
    updateDataByYear(start, end, index) {
      const keyDataChange = ['target_emissions_auto', 'target_emissions'];
      keyDataChange.forEach((keyChange) => {
        let newData = {}
        const oldEmission = JSON.parse(this.itemCompares[index][keyChange])
        for (let index = start; index <= end; index++) {
          newData[index] = oldEmission[index] || ''
        }
        this.itemCompares[index][keyChange] = JSON.stringify(newData)
        this.informationYearCompare[index][keyChange] = JSON.stringify(newData)
        this.$emit('updateYearCompareCustome', index, newData)
      });
    },
    updateTitle(newTitle, index) {
      this.itemCompares[index].title = newTitle
      this.informationYearCompare[index].title = newTitle
      this.$emit('updateDataTitle', index, newTitle)
    },
    updateIsInputCarbon(newVal) {
      this.$emit('updateIsInputCarbon', newVal)
    },
    getCarbonValue(dataCarbonJson) {
      let data = {}
      if(dataCarbonJson) {
        data = JSON.parse(dataCarbonJson)
      }
      let newData = {}
      const endYear = this.itemCompares[0]?.end || this.dataSetting.endAt
      const startYear = this.itemCompares[0]?.start || this.dataSetting.startAt
      for (let index = startYear; index <= endYear; index++) {
        newData[index] = dataCarbonJson ? data[index] : ''
      }
      return JSON.stringify(newData)
    },
    getDataEmission(dataJsonEmission) {
      let data = {}
      if(dataJsonEmission) {
        data = JSON.parse(dataJsonEmission)
      }
      const endYear = this.itemCompares[0]?.end || this.dataSetting.endAt
      const startYear = this.itemCompares[0]?.start || this.dataSetting.startAt
      let newData = {}
      for (let index = startYear; index <= endYear; index++) {
        newData[index] = dataJsonEmission ? data[index] : ''
      }
      return JSON.stringify(newData)
    },
    handleGetYearCompareItem(inforYearCompare) {
      const keyTypeItem = inforYearCompare.typeInput ? 'target_emissions_auto': 'target_emissions';
      if(this.indexDelete === inforYearCompare.index && inforYearCompare.index !== 0) return 
      this.informationYearCompare[inforYearCompare.index] = { ...this.informationYearCompare[inforYearCompare.index], ...inforYearCompare };
      this.informationYearCompare[inforYearCompare.index][keyTypeItem] = inforYearCompare && inforYearCompare[keyTypeItem];
      this.itemCompares[inforYearCompare.index].start = inforYearCompare?.start_year;
      this.itemCompares[inforYearCompare.index].end = inforYearCompare?.end_year;
      this.itemCompares[inforYearCompare.index].years = Object.keys(inforYearCompare && inforYearCompare[keyTypeItem]);

      Object.values(inforYearCompare && inforYearCompare[keyTypeItem]).forEach((value, index) => {
        if(value) {
          this.itemCompares[inforYearCompare.index].value[index] = value
        }
      })

      this.$emit('updateYearCompare', this.informationYearCompare)
    },
    handleGetYearItem(isValid, years){
      this.$emit('updateCanNextStep', isValid, years)
    },
    handleAddCompareItem() {
      this.$emit('updateCanNextStep', false)
      let dataYears = []
      for (let index = this.startAt; index <= this.endAt; index++) {
        dataYears.push(index);
      }
      const item = {
        title: ``,
        years: dataYears,
        value: [],
        typeInput: 0,
        target_emissions: this.getDataEmission(),
        target_emissions_auto: this.getDataEmission()
      };

      this.itemCompares.push(item);
      this.reductions.push({
        text: `${this.$t('register_reduction.title_form_compare_object')}${this.reductions.length}`,
        id: this.reductions.length
      })
      this.$emit('handleAddItemCompare', item)
      this.indexAdd ++
      this.handleScroll()
    },
    handleShowDeleteCompareItem(index) {
      this.indexDelete = index;
      this.dialogDeleteCompare = true;
    },
    handleDeleteCompareItem() {
      this.itemCompares.splice(this.indexDelete, 1);
      this.reductions.splice(this.indexDelete, 1);
      this.informationYearCompare.splice(this.indexDelete, 1);
      this.isValids.splice(this.indexDelete, 1);
      // this.itemCompares = JSON.parse(JSON.stringify(this.itemCompares))
      // this.informationYearCompare = JSON.parse(JSON.stringify(this.informationYearCompare))
      this.updateReduction()
      this.indexDelete = null
      this.key = Math.random()
      this.checkIsValidItem()
      this.dialogDeleteCompare = false;
      this.handleScroll()
    },
    handleScroll() {
      const scrollTopPage = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isStickyLeftMenu = scrollTopPage >= 300 ? true : false
      const scrollTop = scrollTopPage;
      const items = document.querySelectorAll('.compare-emission > .right-site > .reduction-item');

      let itemsHeight = []
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const itemHeight = item.offsetHeight;
        itemsHeight.push(itemHeight)
      }
      this.itemsHeight = itemsHeight

      if(itemsHeight.length === 1) {
        this.activeIndex = 0
        return
      }
      const positionItem1 = 300 + itemsHeight[0]
      const positionItem2 = positionItem1 + itemsHeight[1] || 0
      const positionItem3 = positionItem2 + itemsHeight[2] || 0
      if(scrollTop < positionItem1) {
        this.activeIndex = 0
      } else if(scrollTop >= positionItem1 && (scrollTop < positionItem2 || itemsHeight.length === 2)) {
        this.activeIndex = 1
      } else if(scrollTop >= positionItem2 && (scrollTop < positionItem3 || itemsHeight.length === 3)) {
        this.activeIndex = 2
      } else if(scrollTop >= positionItem3 && itemsHeight.length === 4) {
        this.activeIndex = 3
      } else {
        this.activeIndex = 0
      }
    },
    handleScrollToItem(index) {
      const positionItem1 = 300 + this.itemsHeight[0]
      const positionItem2 = positionItem1 + this.itemsHeight[1] || 0
      const positionItem3 = positionItem2 + this.itemsHeight[2] || 0
      let scrollHeight = 0
      // const scrollHeight = index === 0 ? 300 : index === 1 ? positionItem1 : positionItem2
      switch (index) {
        case 0:
          scrollHeight = 300
          break;
        case 1:
          scrollHeight = positionItem1
          break;
        case 2:
          scrollHeight = positionItem2
          break;
        case 3:
          scrollHeight = positionItem3
          break;
        default:
          break;
      }
      
      window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth'
      });
    },
    handleUpdateIsValidItem(newVal, index) {
      this.isValids[index] = newVal
      this.checkIsValidItem()
    },
    checkIsValidItem() {
      if(this.isValids.every(item => item === true) && this.isValids.length === this.itemCompares.length) {
        this.$emit('updateCanNextStep', true)
      } else {
        this.$emit('updateCanNextStep', false)
      }
    },
    filterYearInObjectReturnArr(objString) {
      const resultObject = JSON.parse(objString);
      const keysArray = Object.keys(resultObject);
      return keysArray;
    },
    handleGetYearFormat(target_emissions, newStartYear = 1, newEndYear = 1) {
      const targetEmissions = JSON.parse(target_emissions)
      const keysArray = Object.keys(targetEmissions);
      const valueArray = Object.values(targetEmissions);
      let result = []

      const oldStartYear = Number(keysArray[0])
      const oldEndYear = Number(keysArray[keysArray.length - 1])

      //case edit start year
      const rangeYearStartChange = oldStartYear - newStartYear
      if(rangeYearStartChange > 0) {
        for (let index = 0; index < rangeYearStartChange; index++) {
          result[index] = ''
        }
      }
      for (let index = 0 + rangeYearStartChange; index < keysArray.length + rangeYearStartChange; index++) {
        result[index] = addThoundSandComma(valueArray[index - rangeYearStartChange])
      }
      //case edit end year
      const rangeYearEndChange = newEndYear - oldEndYear
      if(rangeYearEndChange > 0) {
        const curentIndex = keysArray.length + rangeYearStartChange
        for (let index = 0; index < rangeYearEndChange; index++) {
          result[index + curentIndex] = ''
        }
      }
      return result
    },
    handleGetCompareYear() {
      const id = parseInt(this.$route.query?.id );
      if(id || this.dataSetting.has_data_back) {

        // add item target year
        const targetYear = [];
        const newStartYear = this.dataSetting.startAt
        const newEndYear = this.dataSetting.endAt
        // TODO: 416
        targetYear.push({
          title: this.$t('register_reduction.title_form_target_emission'),
          years: this.filterYearInObjectReturnArr(this.dataSetting.target_year.target_emissions),
          value:  this.handleGetYearFormat(this.dataSetting.target_year.target_emissions, newStartYear, newEndYear),
          id: this.dataSetting.target_year.id,
          target_emissions: this.dataSetting.target_year.type_setting ? this.getDataEmission() : this.getDataEmission(this.dataSetting.target_year.target_emissions),
          target_emissions_auto: this.dataSetting.target_year.type_setting ? this.getDataEmission(this.dataSetting.target_year.target_emissions) : this.getDataEmission(),
          carbons: this.dataSetting.target_year.type_setting ? this.getCarbonValue() : this.getCarbonValue(this.dataSetting.target_year.target_offsets),
          carbons_auto: this.dataSetting.target_year.type_setting ? this.getCarbonValue(this.dataSetting.target_year.target_offsets) : this.getCarbonValue(), //todo: update data carbon
          typeInput: this.dataSetting.target_year.type_setting ? 1 : 0,
          isInputCarbon: this.dataSetting.target_year.active_offset,
        });
        this.itemCompares = [ ...targetYear ];

        // add item target compare year
        // ?. in case back and target hasn't any compare item
        if(this.dataSetting.target_compare_year?.length > 0) {
          for(let i = 0; i < this.dataSetting.target_compare_year.length; i++) {
            const compareItem = this.dataSetting.target_compare_year[i]
            let startAtCompare = (this.dataSetting.startAt <= compareItem.start_year && compareItem.start_year <= this.dataSetting.endAt) ? compareItem.start_year : this.dataSetting.startAt;
            let endAtCompare = (this.dataSetting.startAt <= compareItem.end_year && compareItem.end_year <= this.dataSetting.endAt) ? compareItem.end_year : this.dataSetting.endAt;
            const emissionsCompare = JSON.parse(compareItem.target_emissions);
            Object.keys(emissionsCompare).forEach(year => {
              emissionsCompare[year] = '';
            });
            this.itemCompares.push({
              title: compareItem?.title,
              years: this.filterYearInObjectReturnArr(compareItem.target_emissions),
              value: this.handleGetYearFormat(compareItem.target_emissions, startAtCompare, endAtCompare),
              start_year: compareItem.start_year,
              end_year: compareItem.end_year,
              id: compareItem?.id,
              target_emissions: compareItem.type_setting ? this.getDataEmission(JSON.stringify(emissionsCompare)) : this.getDataEmission(compareItem.target_emissions),
              target_emissions_auto: compareItem.type_setting ? this.getDataEmission(compareItem.target_emissions) : this.getDataEmission(JSON.stringify(emissionsCompare)),
              typeInput: compareItem.type_setting ? 1 : 0,
            });
            this.reductions.push({
              text: `${this.$t('register_reduction.title_form_compare_object')}${i + 1}`,
              id: i + 2
            })
            this.indexAdd += (i + 2);
            this.handleScroll()
          }
        }
      } else {
        let dataYears = []
        for (let index = this.startAt; index <= this.endAt; index++) {
          dataYears.push('');
        }
        this.itemCompares = [
          {
            title: this.$t('register_reduction.title_form_target_emission'),
            years: listRangeYear(this.startAt, this.endAt),
            value: dataYears,
            target_emissions: this.getDataEmission(),
            carbons: this.getCarbonValue(),
            typeInput: 0,
            target_emissions_auto: this.getDataEmission(),
            carbons_auto: this.getCarbonValue(),
          },
        ];
      }
    },
    updateReduction() {
      this.reductions = []
      this.itemCompares.forEach((item, index) => {
        this.reductions.push({
          text: index === 0 ? this.$t('register_reduction.title_form_target_emission') : `${this.$t('register_reduction.title_form_compare_object')}${index}`,
          id: index
        })
      })
    },

    handleUpdateTypeInput(newVal, index, isInputCarbon) {
      this.itemCompares[index].typeInput = newVal
      this.$emit('updateTypeInput', newVal, index, isInputCarbon)
      if(newVal) {
        let keys = ['target_emissions_auto'];
        if(isInputCarbon && index === 0) {
          keys.push('carbons_auto');
        }
        const self = this;
        keys.forEach((key, indexKey) => {
          const item = self.itemCompares[index]
          const itemTargetEmissions = JSON.parse(item[key])
          item.itemTargetEmissions = itemTargetEmissions
          const startYear = item.start
          const endYear = item.end
          const startYearTagetVal = itemTargetEmissions[startYear]
          const endYearTagetVal = itemTargetEmissions[endYear]
          const rangeYear = endYear - startYear
          const isCalcEmissionsByRangeYearValue = rangeYear > 1
          
          if(isCalcEmissionsByRangeYearValue && endYearTagetVal && startYearTagetVal) {
            const data = { item, startYearTagetVal, endYearTagetVal, rangeYear, endYear, startYear }
            const newVal = calcRangeYearByStartAndEndData(data, key)
            if(indexKey) {
              self.itemCompares[index].carbons_auto = JSON.stringify(newVal)
            } else {
              self.itemCompares[index].target_emissions_auto = JSON.stringify(newVal)
            }
          }
        });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>

<style lang="scss" scoped>
.compare-emission {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
  .left-site {
    display: none;
  }
  .right-site {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-plus {
      .common-btn {
        width: 100%;
        .v-btn__content {
          .icon {
            display: block;
          }
          .icon-active {
            display: none;
          }
        }
        &:hover {
          .v-btn__content {
            .icon-active {
              display: block;
            }
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .compare-emission {
    &.sticky-menu {
      .left-site {
        width: 220px;
        left: calc(101px + 190px);
        &.no-expand {
          left: calc(101px + 105px);
        }
      }
    }
  }
}

@include desktop {
  .compare-emission {
    display: flex;
    flex-direction: row;
    gap: 48px;
    .left-site {
      display: block;
      width: 240px;
      min-width: 240px;
      // position: sticky;
      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 8px;
        padding: 8px 10px;
        border-bottom: 2px solid rgba(121, 134, 134, 0.12);
        cursor: pointer;
        &__label {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.42px;
          color: $monoBlack;
          max-width: 192px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;
        }

        &:hover {
          border-bottom: 2px solid $blueMid;
        }

        &.item-active {
          border-bottom: 2px solid $blueMid;
        }
      }
    }
    .right-site {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: calc((100% - 240px - 48px));
      min-width: calc((100% - 240px - 48px));
    }
    &.sticky-menu {
      gap: 0;
      .left-site {
        position: fixed;
        top: 128px;
      }
      .right-site {
        margin-left: 288px;
      }
    }
  }
}

</style>
